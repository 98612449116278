import { ERROR, INFO } from 'constants';
import _ from 'lodash';
import { confirm, fetchJAVA, getUrl, openExportCentre, showInfo } from 'utils';
import { errmsgTip } from './newTips';

function getPathInfo() {
  const route = location.pathname.split('/').filter(Boolean);
  return {
    controller: route[0],
    action: route[1],
  };
}

const defaultGetSelectIds = ({ that }) => {
  const { category = '' } = that?.tableInfo ?? {};
  const selects = that?.vTable?.getStateSelecteRows() || [];
  const uniqueKey = category === 'Batch' ? 'uuid' : 'id';
  return selects.map(val => val?.[uniqueKey]);
};

export const OLD_EXPORT_TYPE_ENUM = {
  export_all_by_reservation: {
    key: 'all',
  },
  export_all_by_goods: {
    key: 'all_goods',
    title: '导出全部-按货物',
  },
  // 导出全部-按订单委托单号
  export_all_by_od_entrust: {
    key: 'all_od_entrust',
    title: '导出全部-按订单委托单号',
  },
  // *********** 分割线 ***********
  export_selected_by_goods: {
    key: 'select_goods',
    title: '导出选中-按货物',
    getSelectIds: defaultGetSelectIds,
  },
  export_selected_by_reservation: {
    key: 'select',
    getSelectIds: defaultGetSelectIds,
  },
  // 导出选中-按订单委托单号
  export_selected_by_od_entrust: {
    key: 'select_od_entrust',
    title: '导出选中-按订单委托单号',
    getSelectIds: defaultGetSelectIds,
  },
  // 导出选中-按装车清单
  export_selected_by_od_loads: {
    key: 'select_od_loads',
    title: '导出选中-按装车清单',
    getSelectIds: defaultGetSelectIds,
  },
};
// 导出前校验
export const DEFAULT_BEFORE_EXPORT = that => true;

// 获取导出接口请求参数Req
export const DEFAULT_FETCH_PARAMS_FN = that => {
  const req = that?.currFetchParamCache ?? {}; // 加载当前ListPage（即上一次请求）的请求参数

  // 获取筛选条件 -- 这种方式获取到的筛选条件，是表格引擎解析后，经过默认值处理的筛选条件。如果有特殊字段的处理，请在下方的配置中，自行覆盖
  if (that?.getApiSearchFilter) {
    const queryFilter = that.getApiSearchFilter(undefined, undefined, true);
    Object.assign(req, queryFilter);
  }
  // 获取排序条件
  if (that?.getUserSort) {
    const sort = that.getUserSort();
    req.sort = sort;
  }
  return req;
};
// 获取选中IDS
export const DEFAULT_SELECTED_IDS_FN = (that, key) =>
  (that?.getStateSelecteRows() ?? []).map(item => (key ? item[key] : item.uuid || item.id));
// 获取当前页面参数
export const DEFAULT_PAGE_CONFIG_FN = that => {
  const { category, tab, url } = that?.tableInfo ?? {};
  const { action, controller } = that?.props.config || getPathInfo();
  const { menuName } = that?.props.getPageInfo() ?? {};

  // 目前仅包含传递给消息提醒的参数，未包含总条数。以下几个参数是必须的
  const pageConfig = {
    identify: `${controller}_${action}`,
    category,
    tab,
    module: menuName,
    url,
  };
  return pageConfig;
};

// 前端-同步导出
export const DEFAULT_SYNC_EXPORT_OP_FN = async ({ url, fetchParam, ids, format = 'zip' } = {}) => {
  const handleDownload = async () => {
    // 可以自定义导出url
    window.open(getUrl(url, { req: JSON.stringify({ ...fetchParam, ids, format }) }));
    const retry = await confirm(INFO, '是否已下载成功?', { confirm: '重新下载', cancel: '关闭' }, '操作确认');
    if (retry) {
      return handleDownload();
    }
    return true;
  };
  return handleDownload();
};

// 导出中心-异步导出
export const DEFAULT_ASYNC_EXPORT_OP_FN = ({ url, fetchParam, ids } = {}) => {
  const param = { method: 'POST', body: { req: { ...fetchParam, ids } } };

  return fetchJAVA(url, param).then(res => {
    const isBadRes = !res || (res.errno !== 0 && res.errno !== undefined); // errno 不为 0 且不是 undefined 时，表示有错误
    if (isBadRes) {
      showInfo(ERROR, res.errmsg || '导出失败，请稍后重试或联系管理员。');
      return;
    }
    showInfo(INFO, '开始导出，请在导出中心查看详情！');
    openExportCentre();
  });
};

export const DEFAULT_EXPORT_URL = `/Table/Export/multiSheetExport`;
export const DEFAULT_ASYNC_EXPORT_URL = `/cyt-batch-export-service/export/batch/image/export`;

export const EXPORT_TYPE = {
  DEFAULT_SYNC_EXPORT: {
    type: 'DEFAULT_SYNC_EXPORT',
    fn: DEFAULT_SYNC_EXPORT_OP_FN,
    // url: DEFAULT_EXPORT_URL, 默认使用ListPage的URL
  },
  DEFAULT_ASYNC_EXPORT: {
    type: 'DEFAULT_ASYNC_EXPORT',
    fn: DEFAULT_ASYNC_EXPORT_OP_FN,
    url: DEFAULT_ASYNC_EXPORT_URL,
  },
};
// 获取导出配置
export const DEFAULT_GET_FETCH_CONFIG = ({ url } = {}) => {
  return { ...EXPORT_TYPE.DEFAULT_SYNC_EXPORT, url }; // 同步导出
};

const DEFAULT_EXPORT_FILE =
  ({ key = 'receipt_img', max = 200, title = '电子回单', isRequireChecked = true } = {}) =>
  that => {
    const selectsHeader = that.getHeader();
    if (!selectsHeader[key] && isRequireChecked) {
      errmsgTip(`请您在列表设置中把“${title}”勾选显示`);
      return [];
    }
    const fileList = that.getSelectesKey([key]);
    const files = fileList.reduce((prev = [], cur) => prev.concat(cur[key] || []), []);
    if (!files.length) {
      errmsgTip(`当前没有可导出的${title}`);
      return [];
    }
    if (files.length > max) {
      errmsgTip(`您勾选的${title}下载超过${max}张, 请重新勾选数据！`);
      return [];
    }
    return files;
  };

export const DEFAULT_EXPORT_FILE_FN = DEFAULT_EXPORT_FILE();

// 油耗分析
const oilAnalysisExport = {
  beforeExport: DEFAULT_BEFORE_EXPORT,
  getFetchParams: that => {
    const req = DEFAULT_FETCH_PARAMS_FN(that);
    const data = that?.getData() ?? [];
    const task_id = data[0]?.task_id;
    _.set(req, ['query', 'task_id'], task_id);
    return req;
  },
  getPageConfig: DEFAULT_PAGE_CONFIG_FN,
  getFetchConfig: (...rest) => {
    const defaultFetchConfig = DEFAULT_GET_FETCH_CONFIG(...rest);
    return {
      ...defaultFetchConfig,
      url: DEFAULT_EXPORT_URL,
    };
  },
};

// 订单回单图片导出
const orderImgExport = {
  beforeExport: DEFAULT_BEFORE_EXPORT,
  getFetchConfig: () => {
    return {
      ...EXPORT_TYPE.DEFAULT_ASYNC_EXPORT,
      url: '/cyt-batch-export-service/export/order/image/export',
    };
  },
};

// 按表格导出回单，全部走异步导出
const exportReceiptExcel = ({ max = 2000 } = {}) => ({
  // 按表格导出不需要校验是否勾选电子回单字段
  getExportFiles: DEFAULT_EXPORT_FILE({ key: 'receipt_img', max, title: '电子回单', isRequireChecked: false }),
  getFetchConfig: () => {
    return { ...EXPORT_TYPE.DEFAULT_ASYNC_EXPORT };
  },
  getFetchParams: that => {
    // 取当前导出页面的 category, tab 信息
    const { category, tab } = that.tableInfo ?? {};

    return {
      b_basic_ids: DEFAULT_SELECTED_IDS_FN(that, 'b_basic_id'),
      image_type: 'receipt_img',
      export_ways: 'by_excel',
      category,
      tab,
      download_type: 3,
    };
  },
});

// 回单导出 downloadType: 1 装卸不分开(一般导出) 2 装卸分开
const exportReceiptImage = ({ max = 2000, asyncNum = 200, downloadType = 1 } = {}) => ({
  getExportFiles: DEFAULT_EXPORT_FILE({ key: 'receipt_img', max, title: '电子回单' }),
  getFetchConfig: ({ files, ...rest } = {}) => {
    // 回单导出大于max张，走异步导出
    if (files.length > asyncNum && files.length <= max) {
      return { ...EXPORT_TYPE.DEFAULT_ASYNC_EXPORT };
    }
    return DEFAULT_GET_FETCH_CONFIG(rest);
  },
  getFetchParams: (that, { files, ids } = {}) => {
    // 回单导出大于max张，走异步导出
    if (files.length > asyncNum && files.length <= max) {
      return {
        b_basic_ids: DEFAULT_SELECTED_IDS_FN(that, 'b_basic_id'),
        image_type: 'receipt_img',
        download_type: downloadType,
      };
    }
    return { ...DEFAULT_FETCH_PARAMS_FN(that), download_type: downloadType };
  },
});

// 在途费用导出
const exportOnWayFeeImage = {
  getExportFiles: DEFAULT_EXPORT_FILE({ key: 'receipt_img', max: 500, title: '电子回单' }),
};

// 车辆维保导出
const bsCarmaintenanceExport = (type = 'wx') => {
  const title = type === 'wx' ? '维修照片' : '保养照片';
  const max = 2000;
  return {
    getExportFiles: DEFAULT_EXPORT_FILE({ key: 'bs_carmaintenance_img', max, title, isRequireChecked: false }),
    getFetchConfig: () => ({
      ...EXPORT_TYPE.DEFAULT_ASYNC_EXPORT,
      url: '/cyt-vehicle-biz-v1/maintenance/export/resource',
    }),
    getFetchParams: () => ({ type }),
  };
};

/**
 * 导出配置
 * 格式：`${category}_${tab}_${buttonKey}` 或者 `${controller}_${action}_${buttonKey}`
 * 示例：
 * BsOilSegmentMonthDriver_tt_tr_oil_analysis_v1_export_detail_all: {
 *   beforeExport: DEFAULT_BEFORE_EXPORT, // 导出前校验
 *   getFetchParams: DEFAULT_FETCH_PARAMS_FN, // 获取导出接口请求参数Req
 *   getSelectedIds: DEFAULT_SELECTED_IDS_FN, // 获取选中IDS
 *   getPageConfig: DEFAULT_PAGE_CONFIG_FN, // 获取当前页面参数
 * }
 */
export default {
  // 油耗分析
  BsOilSegmentMonthDriver_tt_tr_oil_analysis_v1_export_detail_all: oilAnalysisExport,
  BsOilSegmentMonthDriver_tt_tr_oil_analysis_v1_export_detail_selected: {
    ...oilAnalysisExport,
    getSelectedIds: that => {
      const selectedRows = that?.getStateSelecteRows() ?? [];
      return selectedRows.map(item => _.pick(item, ['dr_id', 'dr_name', 'approval_ids']));
    },
  },
  // 订单回单照片导出
  Order_receiptTotal_export_image: orderImgExport,
  // 回单导出
  Schedule_receiptImgAudit_export_image: exportReceiptImage({
    asyncNum: 0,
  }),
  // 磅单管理导出
  Schedule_BulkReceiptImgAudit_export_image: exportReceiptImage({
    asyncNum: 0,
  }),
  // 在途费用导出
  Schedule_onWayFeeList_export_image: exportReceiptImage({
    asyncNum: 0,
  }),
  Schedule_onWayFeeList_export_onway_image: {
    getExportFiles: DEFAULT_EXPORT_FILE({ key: 'pic', max: 200, title: '图片' }),
    getFetchConfig: () => {
      return { ...EXPORT_TYPE.DEFAULT_ASYNC_EXPORT, url: '/cmm-basic/batchOnwayFee/export/image' };
    },
    getFetchParams: (that, fnParam) => {
      const { ids } = fnParam;
      return {
        uuids: ids,
      };
    },
  },
  // 运单回单 导出图片-装卸分开
  Schedule_receiptImgAudit_export_image_separate: exportReceiptImage({ downloadType: 2, asyncNum: 0 }),
  // 运单回单 按表格导出回单
  Schedule_receiptImgAudit_export_image_by_excel: exportReceiptExcel({ asyncNum: 0 }),
  // 磅单管理 导出图片-装卸分开
  Schedule_BulkReceiptImgAudit_export_image_separate: exportReceiptImage({ downloadType: 2, asyncNum: 0 }),
  // 磅单管理 按表格导出磅单
  Schedule_BulkReceiptImgAudit_export_image_by_excel: exportReceiptExcel({
    asyncNum: 0,
  }),
  // 车辆维修
  'Back_BsCarmaintenance-repair_export_image': bsCarmaintenanceExport('wx'),
  // 车辆保养
  'Back_BsCarmaintenance-carmaintenance_export_image': bsCarmaintenanceExport('by'),
};
