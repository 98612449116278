import { POPUP_CONFIRM_WINDOW } from '@/utils/i18n/constants.js';
import { i18n } from '@/utils/i18n/index.js';
import actions from 'actions';
import { BatchOp, CheckBox, Dialog, DialogDetail, PopTip, PopUp, ShowInfo } from 'components';
import getBatchDetail from 'components/commoncomponents/BatchDetail';
import insureOrder from 'components/commoncomponents/insurance/insureOrder'; // 投保弹窗
import OrderEditor from 'components/commoncomponents/orderEditor';
import { payModeKeys } from 'components/commoncomponents/orderEditor/constant';
import MergeReservationModal from 'components/commoncomponents/orderEditor/mergeReservation';
import commonErrorTip from 'components/commoncomponents/orderEditor/public/commonErrorTip';
import SplitReservationModal from 'components/commoncomponents/orderEditor/splitReservation';
import SplitReservationManual from 'components/commoncomponents/orderEditor/splitReservation/manual';
import popUpWithRemark from 'components/commoncomponents/popUpWithRemark';
import { CHECK, ERROR, INFO, TRANS_FIELD_MAP, WARN } from 'constants';
import _ from 'lodash';
import moment from 'moment';
import { reportSegmentName } from 'operation/listOperation/reportsOp';
import VirtualShip from 'operation/listOperation/VirtualShip/VirtualShip';
import abnormalMF from 'pages/OrderController/abnormalManage';
import { AsyncOrder } from 'pages/OrderController/coInfo/dynamicImport';
import TransArrivalDialog from 'pages/OrderController/components/TransArrivalDialog';
import orderListPopup from 'pages/OrderController/orderListPopup';
import OrderStateSlide from 'pages/OrderController/pickClip/OrderState';
import { clearAll, clearItem } from 'pages/OrderController/pickClip/removePickOrder';
import batchEditReservations from 'pages/OrderController/reservation/batchEdit';
import { AsyncReservation } from 'pages/OrderController/reservation/dynamicImport';
// import BatchR2OModal from 'components/commoncomponents/orderEditor/batchR2O'
import BatchToTaskModal from 'pages/ScheduleController/task/components/taskEditor/batchR2O';
import CancelRelateModal from 'pages/ScheduleController/task/components/taskEditor/cancelRelate';
import signSlider from 'pages/SignController/load/components/signSlider';
import moreGoodsChange from 'pages/TransController/components/moreGoodsChange';
import getSliderTable from 'pages/TransController/load/components/acceptSlider';
import React from 'react';
import browserHistory from 'routes/history';
import {
  alert,
  checkSelected,
  confirm,
  confirmWithReasonSync,
  createTip,
  customDetail,
  fetchApi,
  fetchJAVA,
  fromJS,
  getPageKey,
  handleResponse,
  isset,
  opTips,
  postData as gpostData,
  postData,
  postJAVA,
  remDub,
  showInfo,
  typeIs,
  viewImage,
} from 'utils';
import { directToOrderCreator } from 'utils/business/order/direct';
import { fetchOrderPointTrans } from 'utils/business/order/fetchOrderData';
import pickClipCountManager from 'utils/business/pickClip/countManager';
import { directToTaskCreator } from 'utils/business/task/direct';
import directToTrans from 'utils/business/trans/direct';
import { dataAnalyticTrack, datHelper } from 'utils/dataAnalytic';
import emitter from 'utils/emitter';
import scanReceiptCore from '../scanReceiptCore';
import './orderOp.scss';

const isFromPickClip = listPage => listPage && listPage.getReq && listPage.getReq().tab === 'pick_clip'; // 是否是挑单夹事件
const getData = (keys, that, dataSource) => {
  const obj = {};
  keys.forEach(v => (obj[v] = []));
  if (isFromPickClip(that)) {
    const data = that.getData();
    data.forEach((item, index) => {
      keys.forEach(key => {
        if (!obj[key]) {
          obj[key] = [];
        }
        obj[key][index] = item[key];
      });
    });
  } else if (dataSource) {
    keys.forEach(v => {
      // 有些数据不存在，过滤一下
      const dataSourceFilter = dataSource.filter(item => item[v]);
      return (obj[v] = dataSourceFilter.map(item => item[v]));
    });
  } else {
    keys.forEach(v => (obj[v] = that.getSelectesKey(v)));
  }
  return obj;
};
/**
 * 预到货运单check. 【注意】如果使用 同步函数（async/await）需要在调用该方法时使用trycatch
 * ps.预到货运单在运单列表只能操作打印导出。其他的操作需要过此check
 * @param {*} that   listPage 引用
 * @param {*} opType 提示文字
 * @param {*} keys   返回数据
 * return Promise
 * 如果不传keys,返回勾选的所有行数据，否则返回由keys为key组成的对象。
 */
export const preArrivalCheck = (that, opType, keys) =>
  new Promise((resolve, reject) => {
    if (isFromPickClip(that)) {
      if (keys) {
        resolve(getData(keys, that));
      }
      resolve(that.getData());
    }
    // 运单详情走此逻辑
    if (!that.vTable.getStateSelectes) {
      resolve(keys ? getData(keys, that) : that.getData());
      return;
    }
    const selects = that.vTable.getStateSelectes() || [];
    const data = that.getData();
    const dataSource = selects.map(index => ({ ...data[index], index }));
    const failedDetail = [];
    const successId = [];
    // const comId = window.company_id
    dataSource.forEach(item => {
      // is_op = 1 | 0  . = 0的时候是预到货运单
      const isOp = item.is_op;
      if (+isOp === 0) {
        failedDetail.push({
          msg: '是预到货运单',
          number: item.order_num,
        });
      } else {
        successId.push(item);
      }
    });
    if (failedDetail.length) {
      opTips({
        opType, // 操作类型， 对应提示文本
        resData: {
          errno: successId.length ? 0 : ':(',
          res: {
            failed_detail: failedDetail,
            success_id: successId,
          },
        },
        continueCallback(ids, cb) {
          cb();
          resolve(keys ? getData(keys, that, successId) : successId);
        }, // 继续 btn 对应的回调
        ext: {
          failedCallback() {
            reject();
          },
        },
      });
    } else {
      resolve(keys ? getData(keys, that, successId) : successId);
    }
  });
const getConfirmInfo = (tips, msg) => `您确定${tips}吗? ${msg.confirm || ''}`;
// const checkSelected = function (selected, tips = '请选择操作项', one) {
//   if (!selected || selected.length <= 0) {
//     showInfo(INFO, tips)
//     return false
//   } else if (one && selected.length > 1) {
//     showInfo(INFO, tips) // '只能操作一个项'
//     return false
//   }
//   return true
// }
const postDataOperate = (
  url,
  pack,
  tips = '',
  msg,
  callback,
  opType = '',
  ext,
  orderType,
  successIDkey = 'orders',
  isJAVAFetch,
) => {
  // ext && ext.that && ext.btnKey && ext.that.disableMenuButton && ext.that.disableMenuButton(ext.btnKey)
  const fetchFn = isJAVAFetch ? fetchJAVA : fetchApi;
  if (isJAVAFetch) {
    fetchJAVA(url, {
      method: 'POST',
      body: { req: pack },
    })
      .then(res => {
        opTips({
          opType: '',
          orderType: '',
          resData: res,
          sucCallback: () => {
            setTimeout(() => {
              typeof callback === 'function' && callback(res);
            }, 1000);
          },
          ext: {
            failedCallback: () => {},
            cancelBtnName: '确定',
            customErrRule: '',
          },
        });
      })
      .catch(() => showInfo(ERROR, '网络错误'));
  } else {
    postData(url, pack, res => {
      opTips({
        orderType,
        tips,
        opType,
        resData: res.data,
        sucCallback: () => {
          typeof callback === 'function' && callback(res);
        },
        continueCallback: (sucId, checkCallback) => {
          if (typeof checkCallback === 'function') checkCallback();
          const newPack = pack;
          newPack.is_check = 0;
          newPack[successIDkey] = res.data.res.success_id;
          fetchFn(url, { method: 'POST', body: { req: newPack } }).then(secRes => {
            if (typeof callback === 'function') callback(secRes);
            if (secRes.data !== undefined && secRes.data.errno === 0) {
              showInfo(CHECK, `${tips}成功`);
            } else {
              showInfo(ERROR, secRes.data.errmsg || `${tips}失败`);
              ext && ext.failedCallback && ext.failedCallback();
            }
          });
        },
        ext: {
          failedCallback: ext && ext.failedCallback,
        },
      });
    });
  }
};
const operate = (url, pack, tips, msg, callback, opType, ext, orderDict) => {
  new PopUp(ShowInfo, {
    type: INFO,
    content: getConfirmInfo(tips, msg),
    title: tips,
    simpleMode: {
      confirm: (thisDialog, btnKey) => {
        console.log(btnKey);
        thisDialog && btnKey && thisDialog.changeBtnLoadingStatus(btnKey, true);
        postDataOperate(
          url,
          pack,
          tips,
          msg,
          () => {
            thisDialog.handleHide();
            callback && callback();
          },
          opType,
          {
            ...ext,
            failedCallback: () => {
              if (thisDialog) {
                thisDialog && thisDialog.handleHide();
                btnKey && thisDialog.changeBtnLoadingStatus(btnKey, false);
              }
            },
          },
          orderDict,
        );
      },
      cancel: thisDialog => thisDialog.handleHide(),
    },
  }).show();
};

function checkComId(comId, tips) {
  const _tips = tips || '一次只能操作一个网点的数据！';
  if (comId.length > 1) {
    showInfo(ERROR, _tips);
    return false;
  }
  return true;
}

const getPackData = (that, isInner, isUp) => {
  // eslint-disable-next-line no-nested-ternary
  const batchId = isUp
    ? isInner
      ? 'b_inner_trans_batch_id'
      : 'b_trans_batch_id'
    : isInner
    ? 'b_inner_trans_in_batch_id'
    : 'b_trans_in_batch_id';
  const idIndex = that.getSelectesKey(['od_link_id', batchId]);
  if (idIndex.length === 0) {
    showInfo(INFO, '请选择要处理的运单！');
    return false;
  }
  return {
    orders: idIndex,
  };
};
const batchPaceCallback = (resData, indexs, tableData) => {
  const failed_detail = resData.res.failed_detail || [];
  const rtnData = {};
  indexs.forEach(index => {
    const data = Object.assign({}, { ...resData, res: {} });
    data.res.failed_detail = failed_detail.filter(item => item.number === tableData[index].no);
    data.res.success_id = [];
    data.errmsg = data.errno === 0 ? '' : data.errmsg;
    data.errno = data.res.failed_detail.length === 0 && data.errno === 0 ? 0 : -300;
    rtnData[index] = data;
  });
  return rtnData;
};

function pointBatchTrans(that, selectIds, acceptUrl, rejectUrl) {
  new PopUp(getSliderTable(), {
    popName: 'acceptSlider',
    ids: selectIds,
    sliderTitle: '运单处理',
    sliderType: 'isAccept',
    requestUrl: '/Table/Search/orderList',
    tab: 'trans_point_accept_info',
    callback: (pack, type, closeSlider) => {
      if (type === 'accept') {
        closeSlider();
        new PopUp(BatchOp, {
          title: '批量操作',
          tips: '运单接收',
          data: pack.orders,
          pace: 1,
          // maxInterval: 200,
          pacesCallback: batchPaceCallback,
          header: {
            order_num: { title: '运单号', type: 'Text', display: 'show', width: 100, align: 'left' },
            point_trans_up_com_id: {
              title: '转出网点',
              type: 'Text',
              display: 'show',
              width: 140,
              align: 'left',
              refEnum: 'company_id_perm',
            },
            point_trans_f: { title: '网点中转费', type: 'Text', display: 'show', width: 80, align: 'left' },
          },
          requestUrl: acceptUrl,
          enumerations: pack.enumerations,
          getPara: async dataList => {
            const { od_link_id, pci_uld_f, pci_uld_grp } = dataList[0];
            const res = await fetchOrderPointTrans({ odLinkId: od_link_id });
            if (res.errno !== 0) {
              return {
                error: res.errmsg,
              };
            }
            const orderEditor = new OrderEditor({ data: res, asService: true, usedFor: 'pointTrans' });
            const state = orderEditor.store.getState();
            orderEditor.set('ceeAddrInfo', state.ceeAddrInfo);
            orderEditor.set('corAddrInfo', state.corAddrInfo);
            orderEditor.setTable('pointCostInfo', 0, { pci_uld_f, pci_uld_grp });
            orderEditor.set('pointTransInit');
            const { req } = await orderEditor.getSaveData();
            const { odLinkId, priceMode, oldPriceMode, keyMap } = orderEditor.store.getState();
            req.price_mode = priceMode;
            req.old_price_mode = oldPriceMode;
            payModeKeys.forEach(key => (req[key] = state[keyMap[key]]));
            req.od_link_id = odLinkId;
            req.billing_date = state.billingDate;
            return { orders: [req], diffData: orderEditor.getDiff(req), is_batch: false };
          },
          finishCallback: that.handleRefreshData,
          escBind: false,
        }).show();
      }
      if (type === 'reject') {
        popUpWithRemark({
          title: '拒绝运单',
          labelText: '您确认拒绝当前运单吗？',
          sureCallback: async (reason, dialogRef) => {
            dialogRef.handleHide();
            const rejectData = pack.orders.map(item => ({ trans_type: 3, od_link_id: item.od_link_id, reason }));
            operate(rejectUrl, { orders: rejectData }, '拒绝接收运单', '', () => {
              closeSlider();
              that.handleRefreshData && that.handleRefreshData();
            });
          },
        });
      }
    },
  }).show();
}
// 员工中转处理
function staffTrans(that, selectIds, acceptUrl, rejectUrl) {
  new PopUp(getSliderTable(), {
    popName: 'acceptSlider',
    ids: selectIds,
    sliderTitle: '运单处理',
    sliderType: 'isAccept',
    requestUrl: '/Table/Search/orderList',
    tab: 'trans_inner_accept_info',
    callback: (pack, type, closeSlider) => {
      type === 'accept' &&
        operate(acceptUrl, pack, '运单接收', {}, () => {
          closeSlider();
          that.handleRefreshData && that.handleRefreshData();
        });
      type === 'reject' &&
        operate(rejectUrl, pack, '拒绝接收运单', {}, () => {
          closeSlider();
          that.handleRefreshData && that.handleRefreshData();
        });
    },
  }).show();
}

/**
 * 网点中转处理
 * @param that
 * @param odLinkId
 * @param odBasicId
 * @param transInBatchId
 * @returns {Promise.<void>}
 */
function pointTrans(that, odLinkId, odBasicId, transInBatchId) {
  const url = '/Order/Trans/pointTrans/';
  const req = { od_link_id: odLinkId };
  const conf = { method: 'POST', body: { req, from: 'pc' } };
  const checkPointTrans = fetchApi(url, conf);
  checkPointTrans.then(res => {
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
    } else {
      new PopUp(AsyncOrder, {
        popName: 'pointTrans',
        isWhere: 'pointTrans',
        showType: 'Slider',
        oinfo_id: odLinkId,
        od_basic_id: odBasicId,
        b_inner_trans_in_batch_id: transInBatchId,
        data: res,
        refreshTab: () => setTimeout(that.handleRefreshData, 2000),
      }).show();
    }
  });
}

// 显示信息 , 需要确认
function showMsg(title, content) {
  new PopUp(ShowInfo, {
    type: INFO,
    content,
    title,
    simpleMode: {
      confirm: thisDialog => {
        thisDialog.handleHide();
      },
    },
  }).show();
}

/**
 
 * @desc:    {[取消签收]}
 * @param    {[type]}        that [description]
 * @return   {[type]}             [description]
 */
export const cancelSign = async that => {
  let idIndex;
  try {
    ({ od_link_id: idIndex } = await preArrivalCheck(that, '签收', ['od_link_id']));
  } catch (error) {
    return;
  }
  if (idIndex.length === 0) {
    showInfo(INFO, '请选择运单！');
    return false;
  }
  const url = '/Order/Sign/cancelSign';
  const pack = {
    od_link_ids: idIndex,
  };
  const ext = {
    that,
    btnKey: 'cancel_sign',
  };
  operate(url, pack, '取消签收', {}, that.handleRefreshData, 'signCancel', ext);
};
/*
 单票签收
 */
export const signCheck = function (ext) {
  const { orderId } = ext;
  const url = 'Order/Sign/loadSingleInfo/';
  const param = { od_link_id: orderId };
  postData(url, param, res => {
    if (res.data.errno !== 0) {
      showInfo(WARN, res.data.errmsg);
      return;
    }
    new PopUp(signSlider, { ...ext, initSignInfo: res.data.res }).show();
  });
};
export const signSingle = function (that, rowIndex) {
  const pageKey = getPageKey().pageConfig.key;
  const dataList = that.getTableList([rowIndex]); // , showingData = that.getShowingData([rowIndex])
  if (dataList.length === 0) return false;
  // 只有从到车清单侧拉打开的运单详情页 才有此限制
  if (dataList[0].b_tr_state && dataList[0].b_tr_state !== 40 && that.fromPage === 'Order_tr_down_loading_list') {
    return showInfo(ERROR, '该运单未卸车入库，无法签收！');
  }
  const queryFilter = that.getApiSearchFilter();
  if (!queryFilter) return false;
  const companyId = dataList[0].com_id || isset(queryFilter, 'query.com_id.0') || window.company_id; // ,
  // companyName = showingData[0].com_id || window.company_info.short_name
  const companyIdDefaultSelect = that.getEnumItem('com_id', companyId); // [{ id: companyId, short_name: companyName }]
  const companyIdFetchApi = that.getTableEnumApi('com_id');
  // const idIndex = dataList[0].od_id
  const idIndex = dataList[0].od_link_id;
  const orderNum = dataList[0].order_num;
  const { key } = (getPageKey() || {}).pageConfig || {};
  const slideParm = {
    popName: `operatSlideDrag_${pageKey}`,
    orderId: idIndex,
    callback: that.handleRefreshData,
    orderNum,
    companyId,
    companyIdDefaultSelect,
    companyIdFetchApi: companyIdFetchApi.fetchApi || {},
    pageKey: key,
    tableEnum: that.getTableEnum(),
    categoryTab: {
      category: that.tableInfo.category,
      tab: that.tableInfo.tab,
    },
  };
  signCheck(slideParm);
};
/*
 签收公共操作, 改成先请求数据，再跳转
 */
export const signFunc = function (ids, tab = 'sign_pick', tabName = '签收', ext, that, parentKey, packIds, carBatch) {
  parentKey && that.disableMenuButton && that.disableMenuButton(parentKey);
  const fromPickClip = isFromPickClip(that) || false;
  const tableInfo = that ? that.tableInfo : {};
  // ext 新增对象
  const pack = {
    type: 'Sign',
    od_link_ids: ids,
    tab,
    company_id: ext.company_id,
    fromPickClip,
    b_pack_link_ids: packIds,
    pack_batch: carBatch,
    sign_from: {
      tab: tableInfo.tab,
      b_link_id: tableInfo.b_link_id,
    },
  };
  postData('/Order/Sign/loadBatchInfo', pack, res => {
    parentKey && that.enableMenuButton && that.enableMenuButton(parentKey);
    if (res.data.errno !== 0) {
      const popup = new PopUp(ShowInfo, {
        classname: 'del-customer',
        isShow: true,
        type: ERROR,
        content: res.data.errmsg,
        autoDestroy: true,
        title: '签收',
        simpleMode: {
          cancel: thisDialog => thisDialog.handleHide(),
        },
        closable: true,
        maskClosable: true,
      });
      popup.show();
      return;
    }
    const tabArr = ['settle_pay_arrival', 'pack_default', 'settle_crease'];
    const showPack = (that && that.state && that.state.req && tabArr.indexOf(that.state.req.tab) > -1) || false;
    const urlData = {
      controller: 'Sign',
      action: 'load',
      query: {
        tab,
        tabName,
        fromPickClip,
        showPack,
      },
    };
    let directInfo = { ids, tab: tab || 'sign_pick' };
    directInfo = Object.assign(directInfo, ext);
    directInfo = { ...directInfo, orderNum: ext.orderNum, pickOrderInfo: res.data.res, sendReq: false };
    actions.api.direct(urlData, 'directInfo', directInfo);
  });
};
export const sign = async (that, key, rowIndex, col, selected, buttonData, parentKey) => {
  let idIndex;
  let companyIds;
  let orderNum;
  let bTrState;
  let bLinkIds;
  let carBatch;
  try {
    ({
      od_link_id: idIndex,
      com_id: companyIds,
      order_num: orderNum,
      b_tr_state: bTrState,
      b_link_id: bLinkIds,
      car_batch: carBatch,
    } = await preArrivalCheck(that, '签收', [
      'od_link_id',
      'com_id',
      'order_num',
      'b_tr_state',
      'b_link_id',
      'car_batch',
    ]));
  } catch (error) {
    return;
  }
  if (bTrState && bTrState[0] && bTrState[0] !== 40 && that.fromPage === 'Order_tr_down_loading_list') {
    return showInfo(ERROR, '该运单未卸车入库，无法签收！');
  }
  const ext = {};
  if (companyIds.length > 0) {
    // 勾选运单
    const _comId = remDub(companyIds); // 去重
    if (!checkComId(_comId)) {
      return;
    }
    ext.company_id = _comId[0];
  } else {
    // 没有勾选运单
    const companyType = window.company_info.type;
    const companyId = window.company_id;
    const fiter = that.getAllFitlerQuery();
    const comIds = !isFromPickClip(that) ? fiter.com_id || [] : companyIds;
    if (+companyType === 2 || +companyType === 1) {
      // 当前登陆的是职能组织
      if (comIds.length > 1 || (comIds.length === 1 && comIds[0] === '_NULL_')) {
        // showInfo(ERROR, '不能在“网点”筛选条件中选择多个网点后进行签收操作')
        showMsg('签收', '不能在“网点”筛选条件中选择多个网点后进行签收操作!');
        return false;
      }
      if (comIds.length < 1) {
        // showInfo(ERROR, '请您在“网点”筛选条件中选择一个网点进行签收操作（代表替此网点进行操作)!', '签收')
        showMsg('签收', '请您在“网点”筛选条件中选择一个网点进行签收操作（代表替此网点进行操作)!');
        return false;
      }
      if (comIds.length === 1) {
        ext.company_id = comIds[0];
      }
    } else {
      if (comIds.length > 1 || (comIds.length === 1 && comIds[0] === '_NULL_')) {
        // showInfo(ERROR, '不能在“网点”筛选条件中选择多个网点进行签收操作！')
        showMsg('签收', '不能在“网点”筛选条件中选择多个网点后进行签收操作!');
        return false;
      }
      if (comIds.length < 1) {
        ext.company_id = companyId;
      }
      if (comIds.length === 1 && comIds[0] !== '_NULL_') {
        ext.company_id = comIds[0];
      }
    }
  }
  const signConf = {
    sign: { tab: 'sign_pick', tips: '签收' },
    pickup_sign: { tab: 'pickup_sign_pick', tips: '签收自提运单' },
    delivery_sign: { tab: 'delivery_sign_pick', tips: '签收送货运单' },
    trans_sign: { tab: 'trans_sign_pick', tips: '签收中转运单' },
  };
  ext.orderNum = orderNum;
  signFunc(idIndex, signConf[key].tab, signConf[key].tips, ext, that, parentKey, bLinkIds, carBatch);
};
/*
  员工中转接收中转运单
*/
export const transInnerDeal = function (that) {
  let selectIds = that.getSelectesKey('od_link_id');
  let orderFrom = that.getSelectesKey('order_from_ext');
  orderFrom = orderFrom.map(val => (val === '1' ? '1' : '4'));
  orderFrom = Array.from(new Set(orderFrom)); // 去重
  const acceptUrl = '/Order/Trans/acceptInner';
  const rejectUrl = '/Order/Trans/rejectInner';
  if (selectIds.length === 0) {
    showInfo(INFO, '请选择要处理的运单！');
    return false;
  }
  if (orderFrom.length === 1 && orderFrom[0] === '1' && selectIds.length > 1) {
    // showInfo(WARN, '暂不支持批量处理网点中转运单，请在行内点击处理。')
    pointBatchTrans(that, selectIds, acceptUrl, rejectUrl);
  } else if (orderFrom.length === 1 && orderFrom[0] === '1' && selectIds.length === 1) {
    console.log('pointTrans');
    const odBasicId = that.getSelectesKey('od_basic_id');
    const transInBatchId = that.getSelectesKey('b_inner_trans_in_batch_id');
    pointTrans(that, selectIds[0], odBasicId[0], transInBatchId[0]);
  } else if (orderFrom.length === 1 && orderFrom[0] === '4') {
    staffTrans(that, selectIds, acceptUrl, rejectUrl);
  } else if (orderFrom.length > 1) {
    new PopUp(ShowInfo, {
      showIcon: false,
      content: '所选运单中包含员工中转和网点中转的运单，一次只能处理一种类型的运单，请选择您要处理哪种类型的中转运单？',
      title: '确认',
      buttons: [
        {
          name: '处理员工中转',
          btnType: 'primary',
          cb: thisDialog => {
            selectIds = that.getSelectesKey(['od_link_id', 'order_from_ext']);
            selectIds = selectIds.filter(item => item.order_from_ext === '4');
            selectIds = selectIds.map(item => {
              console.log(item);
              return item.od_link_id;
            });
            staffTrans(that, selectIds, acceptUrl, rejectUrl);
            thisDialog.handleHide();
          },
        },
        {
          name: '处理网点中转',
          btnType: 'default',
          cb: thisDialog => {
            selectIds = that.getSelectesKey([
              'od_link_id',
              'order_from_ext',
              'od_basic_id',
              'b_inner_trans_in_batch_id',
            ]);
            selectIds = selectIds.filter(item => item.order_from_ext === '1');
            if (selectIds.length > 1) {
              pointBatchTrans(
                that,
                selectIds.map(i => i.od_link_id),
                acceptUrl,
                rejectUrl,
              );
              // showInfo(WARN, '暂不支持批量处理网点中转运单，请在行内点击处理。')
            } else {
              pointTrans(
                that,
                selectIds[0].od_link_id,
                selectIds[0].od_basic_id,
                selectIds[0].b_inner_trans_in_batch_id,
              );
            }
            thisDialog.handleHide();
          },
        },
        {
          name: '取消',
          btnType: 'default',
          cb: thisDialog => {
            thisDialog.handleHide();
          },
        },
      ],
    }).show();
  }
};
/*
 员工中转取消中转
 */
export const cancelTrans = function (url, pack, callback, ext) {
  operate(url, pack, '取消中转', {}, callback, 'cancelTrans', ext);
};
export const transInnerCancel = function (that) {
  const pack = getPackData(that, true, true);
  if (pack === false) return;
  const companyIds = that.getSelectesKey('inner_trans_dn_com_id');
  const _comId = remDub(companyIds); // 去重
  if (!checkComId(_comId, '一次只能取消一个网点的数据！')) {
    return;
  }
  const url = 'Order/Trans/cancelInner';
  const ext = {
    that,
    btnKey: 'trans_inner_cancel',
  };
  cancelTrans(url, pack, () => setTimeout(that.handleRefreshData, 2000), ext);
};
/*
 外部中转取消中转
 */
export const transCancel = function (that) {
  const pack = getPackData(that, false, true);
  if (pack === false) return;
  const url = 'Order/Trans/cancel';
  operate(url, pack, '取消中转', {}, that.handleRefreshData, 'cancelTrans', {
    that,
    btnKey: 'trans_cancel',
    closePreDialog: true,
  });
};
/*
 员工中转取消接收
 */
export const cancelTransAccept = function (url, pack, callback, ext) {
  operate(
    url,
    pack,
    '取消接收',
    { confirm: '取消接收的运单将回到“未处理运单”中。', suc: '同时运单已出库' },
    callback,
    'cancelAccept',
    ext,
  );
};
/*
 员工中转取消接收
 */
export const transInnerCancelAccept = function (that) {
  const pack = getPackData(that, true, false);
  if (pack === false) return;
  const companyIds = that.getSelectesKey('order_st_point');
  const _comId = remDub(companyIds); // 去重
  if (!checkComId(_comId)) {
    return;
  }
  const url = 'Order/Trans/cancelAcceptInner';
  cancelTransAccept(url, pack, () => setTimeout(that.handleRefreshData, 300), {
    that,
    btnKey: 'trans_inner_cancel_accept',
  });
};
/*
 公共中转操作
 */
export const transFunc = function (ids, tabName, type, that, orderNum, btnKey) {
  const fromPickClip = isFromPickClip(that) || false;
  directToTrans(ids, tabName, type, orderNum, fromPickClip, that, btnKey);
};
/*
 员工中转
 */
export const transInner = async (that, btnKey) => {
  let idIndex;
  let orderNum;
  try {
    ({ od_link_id: idIndex, order_num: orderNum } = await preArrivalCheck(that, '员工中转', [
      'od_link_id',
      'order_num',
    ]));
  } catch (error) {
    return;
  }
  transFunc(idIndex, '员工中转', 2, that, orderNum, btnKey);
};
/*
 网点中转
 */
export const transPoint = async (that, btnKey) => {
  let idIndex;
  let orderNum;
  try {
    ({ od_link_id: idIndex, order_num: orderNum } = await preArrivalCheck(that, '网点中转', [
      'od_link_id',
      'order_num',
    ]));
  } catch (error) {
    return;
  }
  transFunc(idIndex, '网点中转', 3, that, orderNum, btnKey);
};
/*
 外部中转
 */
export const trans = async (that, btnKey) => {
  let idIndex;
  let orderNum;
  try {
    ({ od_link_id: idIndex, order_num: orderNum } = await preArrivalCheck(that, '外部中转', [
      'od_link_id',
      'order_num',
    ]));
  } catch (error) {
    return;
  }
  // const { od_link_id: idIndex, order_num: orderNum } = getData(['od_link_id', 'order_num'], that)
  transFunc(idIndex, '外部中转', 1, that, orderNum, btnKey);
};
/*
 外部中转接收外部中转运单
 */
export const transDeal = function (that) {
  const pageKey = getPageKey().pageConfig.key;
  const selectIds = that.getSelectesKey('od_link_id');
  if (selectIds.length === 0) {
    showInfo(INFO, '请选择要处理的运单！');
    return false;
  }
  const acceptUrl = '/Order/Trans/accept';
  const rejectUrl = '/Order/Trans/reject';
  new PopUp(getSliderTable(), {
    // popName: 'acceptSlider',
    popName: `operatSlideDrag_${pageKey}`,
    sliderTitle: '运单处理',
    sliderType: 'isAccept',
    ids: selectIds,
    requestUrl: '/Table/Search/acceptInfo',
    tab: 'trans_accept_info',
    callback: (pack, type, closeSlider) => {
      type === 'accept' && operate(acceptUrl, { orders: pack.orders, is_batch: 1 }, '接收运单', {}, closeSlider);
      type === 'reject' &&
        popUpWithRemark({
          title: '拒绝运单',
          labelText: '您确认拒绝当前运单吗？',
          sureCallback: async (reason, dialogRef) => {
            dialogRef.handleHide();
            const rejectData = selectIds.map(item => ({ od_link_id: item, reason }));
            operate(rejectUrl, { orders: rejectData, is_batch: 1 }, '拒绝接收运单', '', () => {
              closeSlider();
              that.handleRefreshData && that.handleRefreshData();
            });
          },
        });
    },
  }).show();
};
/*
 外部中转接收外部中转运单 单个
 */
function outerTransDealSingle(that, rowIndex) {
  const dataList = that.getTableList([rowIndex]);
  const odLinkId = dataList[0].od_link_id;
  const odBasicId = dataList[0].od_basic_id;
  const transInBatchId = dataList[0].b_inner_trans_in_batch_id;
  const url = '/Order/Trans/acceptInfo/';
  const req = { od_link_id: odLinkId };
  const conf = { method: 'POST', body: { req, from: 'pc' } };
  fetchApi(url, conf).then(res => {
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
    } else {
      new PopUp(AsyncOrder, {
        popName: 'outerTransDeal',
        isWhere: 'outerTransDeal',
        showType: 'Slider',
        oinfo_id: odLinkId,
        od_basic_id: odBasicId,
        b_inner_trans_in_batch_id: transInBatchId,
        data: res,
        refreshTab: () => setTimeout(that.handleRefreshData, 2000),
      }).show();
    }
  });
}
/*
 中转修改中转运单
 */
export const transModify = function (that) {
  const pageKey = getPageKey().pageConfig.key;
  const selectIds = that.getSelectesKey('od_link_id');
  if (selectIds.length === 0) {
    showInfo(ERROR, '请选择要修改的运单！');
    return false;
  }
  const theTab = that.getReq().tab;
  const transType = theTab === 'trans_transed' ? 1 : 3;
  const tab = transType === 1 ? 'trans_pick' : 'trans_point_pick';
  const params = {
    // popName: 'acceptSlider',
    popName: `operatSlideDrag_${pageKey}`,
    ids: selectIds,
    sliderType: 'isModify',
    sliderTitle: '已转运单修改',
    requestUrl: '/Table/Search/pickTable',
    tab,
    callback: that.handleRefreshData,
    transType,
  };
  if (transType === 3) {
    const resData = {
      res: {
        failed_detail: [],
        success_id: [],
      },
      errno: 0,
    };
    const msg1 = '为员工中转';
    const msg2 = '下游网点已接收（请先取消接收或前往交易改单修改）';
    const msg3 = '下游网点已拒绝';
    const info = that.getSelectesKey(['trans_type', 'chain_point_trans_st', 'order_num', 'od_link_id']);
    info.forEach(item => {
      if (+item.trans_type === 2) {
        resData.res.failed_detail.push({
          number: item.order_num,
          msg: msg1,
        });
      } else if (+item.chain_point_trans_st === 20) {
        resData.res.failed_detail.push({
          number: item.order_num,
          msg: msg2,
        });
      } else if (+item.chain_point_trans_st === 30) {
        resData.res.failed_detail.push({
          number: item.order_num,
          msg: msg3,
        });
      } else {
        resData.res.success_id.push(item.od_link_id);
      }
    });
    if (resData.res.failed_detail.length) {
      resData.errno = resData.res.success_id.length ? 0 : -999;
      opTips({
        resData,
        opType: '修改',
        orderType: '中转运单',
        continueCallback(sucIDs, cb) {
          cb();
          params.ids = sucIDs;
          new PopUp(getSliderTable(), params).show();
        },
      });
    } else {
      new PopUp(getSliderTable(), params).show();
    }
  } else {
    new PopUp(getSliderTable(), params).show();
  }
};
/*
 外部中转取消接收
 */
export const transCancelAccept = function (that) {
  const pack = getPackData(that, false, false);
  if (pack === false) return;
  const url = 'Order/Trans/cancelAccept';
  operate(url, pack, '取消接收', {}, that.handleRefreshData, '取消接收', { closePreDialog: true });
};
export const pickOrderFunc = function (ids) {
  if (ids.length === 0) {
    showInfo(ERROR, '请选择要操作的运单！');
    return false;
  }
  const pack = {
    orders: ids,
    is_check: 1,
  };
  const url = 'Basic/PickClip/addPickClip';
  postDataOperate(
    url,
    pack,
    '加入挑单夹',
    {},
    res => {
      pickClipCountManager.pickClipCountChange(res.data.res.count);
      // 刷新挑单夹列表
      const pickEmt = emitter.getSpance('pick_clip');
      pickEmt.emit('refreshPickClipData');
    },
    'addPickClip',
  );
};
/*
 员工中转行内处理
 */
export const orderTransHandle = function (that, rowIndex) {
  const dataList = that.getTableList([rowIndex]);
  if (dataList.length === 0) return false;
  // const idIndex = dataList[0].od_id
  const odLinkId = dataList[0].od_link_id;
  const orderFrom = dataList[0].order_from_ext;
  if (orderFrom !== '1') {
    const selectIds = [odLinkId];
    const acceptUrl = '/Order/Trans/acceptInner';
    const rejectUrl = '/Order/Trans/rejectInner';
    staffTrans(that, selectIds, acceptUrl, rejectUrl);
  } else {
    const odBasicId = dataList[0].od_basic_id;
    const transInBatchId = dataList[0].b_inner_trans_in_batch_id;
    pointTrans(that, odLinkId, odBasicId, transInBatchId);
  }
};
/*
 批量加入挑单夹
 */
export const pickOrder = function (that) {
  preArrivalCheck(that, '加入挑单夹')
    .then(dataSource => {
      let ids = dataSource.map(v => v['Order|id']);
      if (!ids[0]) ids = dataSource.map(v => v.id);
      pickOrderFunc(ids);
    })
    .catch(() => {
      console.log('exit');
    });
};
/*
 加入挑单夹行操作
*/
export const orderPickOrder = function (that, rowIndex) {
  const dataList = that.getTableList([rowIndex]);
  if (dataList.length === 0) return false;
  const idIndex = dataList[0].id;
  pickOrderFunc([idIndex]);
};

function showPopTip(type, content, callback) {
  new PopUp(PopTip, {
    type,
    content,
    onClose: () => {
      if (typeof callback === 'function') callback();
    },
  }).show();
}

export const batchBInnerTransBatch = function (that, index, pBatchNo, pBbatchId, pBasicId, urlData, btnKey) {
  const pageKey = getPageKey().pageConfig.key;
  const dataList = that.getTableList([index]);
  if (dataList.length === 0) return false;
  const batchNo =
    (urlData && pBatchNo) ||
    dataList[0].b_point_trans_batch ||
    dataList[0].b_inner_trans_batch ||
    dataList[0].b_trans_batch;
  let batchId = (urlData && pBbatchId) || dataList[0].b_link_id;
  const basicId = (urlData && pBasicId) || dataList[0].b_basic_id || dataList[0].b_inner_trans_basic_id;
  const comId = dataList[0].company_id || dataList[0].com_id;
  const pageInfo = getPageKey(urlData);
  // let key = pageInfo.pageConfig.key
  const tableInfo = pageInfo.pageConfig.tableInfo || {};
  let { tab } = tableInfo;
  // 网点中转批次 转入按照转出显示
  if (tableInfo.tab === 'b_inner_trans_in' && dataList[0].b_point_trans_batch) {
    batchId = dataList[0].b_link_id;
    tab = 'b_point_trans_in';
  }
  if (tableInfo.tab === 'trans_inner_to_trans') {
    tab = 'b_point_trans_in';
  }
  if (pBatchNo === 'b_point_trans_batch' || btnKey === 'b_point_trans_batch_num') {
    // 网点中转时 改成b_point_trans_out
    tab = 'b_point_trans_out';
  }
  const pack = {
    type: tab,
    b_link_id: batchId,
    company_id: comId,
    // trans_type: 2,
  };
  gpostData('/Order/Trans/trans', pack, res => {
    if (res.data.errno !== 0) {
      showPopTip(ERROR, res.data.errmsg);
      return;
    }
    const param = {
      popName: `operatSlideDrag_${pageKey}`,
      batchId,
      batchNo,
      comId,
      basicId,
      pageType: 'show',
      type: tab,
      hasTab: true,
      isEdit: false,
      listPage: that,
      closeCallback: that.handleRefreshData,
      pickOrderInfo: fromJS(res.data.res),
    };
    if (window.company_setting.op_page_type && window.company_setting.op_page_type.value === 'new_page') {
      const pageUrlData = {
        controller: 'Truck',
        action: 'detail',
        query: {
          tabName: param.batchNo,
        },
      };
      actions.api.direct(pageUrlData, 'directInfo', param);
      return;
    }
    new PopUp(getBatchDetail(), param).show();
  });
};
/**
 * 网点中转转出 批次详情
 * @param that
 * @param index
 */
export const orderBPointTransBatchNum = function (that, index, key) {
  const dataList = that.getTableList([index]);
  if (dataList.length === 0) return false;
  const batchNo = dataList[0].b_point_trans_batch_num_ext || dataList[0].b_point_trans_batch_num;
  const batchId = dataList[0].b_inner_trans_batch_id;
  const basicId = dataList[0].b_inner_trans_basic_id;
  const urlData = { controller: 'Transit', action: 'ntransitRollOut' };
  batchBInnerTransBatch(that, index, batchNo, batchId, basicId, urlData, key);
};
/**
 * 网点中转转入 批次详情
 * @param that
 * @param index
 */
export const orderBPointTransInBatchNum = function (that, index, key) {
  const dataList = that.getTableList([index]);
  if (dataList.length === 0) return false;
  const batchNo = dataList[0].b_point_trans_in_batch_num;
  const batchId = dataList[0].b_inner_trans_in_batch_id - 1;
  const basicId = dataList[0].b_inner_trans_basic_id;
  const urlData = { controller: 'Transit', action: 'ntransitShiftTo' };
  batchBInnerTransBatch(that, index, batchNo, batchId, basicId, urlData, key);
};
// 删除运单
export const orderDelete = async (that, rowIndex) => {
  // 参数改为od_basic_id_list 17.3.31 liujie
  let rowData = {};
  let odLinkIdList = [];
  const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
  const orderDict = i18nDict.get('order', '运单');
  if (typeIs(rowIndex, 'number')) {
    rowData = that.getData()[rowIndex] || {};
    odLinkIdList = [rowData.od_basic_id];
  } else {
    try {
      ({ od_basic_id: odLinkIdList } = await preArrivalCheck(that, `删除${orderDict}`, ['od_basic_id']));
    } catch (error) {
      return;
    }
  }
  const pack = { od_basic_id_list: odLinkIdList };
  const url = 'Order/Order/orderDelete';
  if (!checkSelected(odLinkIdList)) return;
  operate(
    url,
    pack,
    `删除${orderDict}`,
    {},
    that.handleRefreshData,
    'orderDelete',
    { closePreDialog: true },
    orderDict,
  );
};
// 转确认
export const transConfirm = async (that, ikey, rowIndex) => {
  console.log(ikey, rowIndex, that);
  // 获取选中行信息
  let optRowInfo;
  try {
    ({ od_link_id: optRowInfo } = await preArrivalCheck(that, '运单转确认', ['od_link_id']));
  } catch (error) {
    return;
  }
  // const { od_link_id: optRowInfo } = getData(['od_link_id'], that)
  if (optRowInfo.length === 0) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  const popup = new PopUp(Dialog, {
    classname: 'trans_confirm',
    loadBtnList: ['confirmTrans'],
    dialogInfo: {
      title: '运单转确认',
      contentHeader: null,
      button: [
        {
          key: 'confirmTrans',
          children: '确定',
          type: 'primary',
        },
        {
          key: 'cancel',
          children: '取消',
        },
      ],
      actionList: {
        confirmTrans: (key, data, validateInfo) => {
          if (!validateInfo.isPass) {
            showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
            return false;
          }
          const option = {
            method: 'POST',
            body: {
              req: {
                data: optRowInfo,
                is_check: 1,
                confirm_time: data.transConfirmTime,
              },
            },
          };
          const url = 'Finance/TransConfirm/transConfirm';
          fetchApi(url, option).then(res => {
            popup && popup.close();
            handleResponse({ operation: '转确认', isCheck: 1 }, res, (succDt, closeResTip) => {
              if (!succDt || succDt.length === 0 || succDt.length === optRowInfo.length) {
                showInfo(INFO, '转确认成功');
                setTimeout(() => {
                  that.handleRefreshData && that.handleRefreshData();
                }, 1000);
                return;
              }
              option.body.req.data = succDt;
              delete option.body.req.is_check;
              fetchApi(url, option).then(dores => {
                closeResTip && closeResTip();
                handleResponse({ operation: '转确认' }, dores, () => {
                  setTimeout(() => {
                    that.handleRefreshData && that.handleRefreshData();
                  }, 1000);
                });
              });
            });
          });
        },
        cancel: () => {
          popup.close();
        },
      },
    },
    data: {
      transConfirmTime: {
        type: 'DateTimePicker',
        eventNames: ['onChange'],
        validate: ['require'],
        labelProps: {
          children: '请确认运单转确认时间',
          isRequired: true,
        },
        otherProps: {
          format: 'YYYY-MM-DD HH:mm:ss',
          defaultValue: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      },
    },
    isShow: true,
  }).show();
};
// 取消转确认
export const cancelTransConfirm = async that => {
  // 获取选中行信息
  let optRowInfo;
  try {
    ({ od_link_id: optRowInfo } = await preArrivalCheck(that, '取消转确认', ['od_link_id']));
  } catch (error) {
    return;
  }
  // const { od_link_id: optRowInfo } = getData(['od_link_id'], that)
  let pack = {};
  pack.data = optRowInfo;
  pack.is_check = 1;
  if (!optRowInfo.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  that.disableMenuButton('cancel_trans_confirm');
  actions.orderServer.cancelTransConfirm(pack).then(resParam => {
    that.enableMenuButton('cancel_trans_confirm');
    handleResponse({ operation: '取消转确认', isCheck: 1 }, resParam, (succDt, closeResTip) => {
      if (!succDt || succDt.length === 0 || succDt.length === optRowInfo.length) {
        showInfo(INFO, '取消转确认成功');
        setTimeout(() => {
          that.handleRefreshData && that.handleRefreshData();
        }, 1000);
        return;
      }
      pack = {
        data: succDt,
      };
      actions.orderServer.cancelTransConfirm(pack).then(dores => {
        closeResTip && closeResTip();
        handleResponse({ operation: '取消转确认' }, dores, () => {
          setTimeout(() => {
            that.handleRefreshData && that.handleRefreshData();
          }, 1000);
        });
      });
    });
  });
};
// 作废运单
export const orderDesert = async (that, rowIndex) => {
  let rowData = {};
  const i18nDict = await i18n(POPUP_CONFIRM_WINDOW);
  const orderDict = i18nDict.get('order', '运单');
  // console.log(i18nDict, 'i18nDict')
  let odLinkIdList = [];
  if (typeIs(rowIndex, 'number')) {
    rowData = that.getData()[rowIndex] || {};
    odLinkIdList = [rowData.od_link_id];
  } else {
    try {
      ({ od_link_id: odLinkIdList } = await preArrivalCheck(that, `作废${orderDict}`, ['od_link_id']));
    } catch (error) {
      return;
    }
    // ({ od_link_id: odLinkIdList } = getData(['od_link_id'], that))
  }
  const type = 'do_deserted'; // do_deserted 运单作废 cancel_deserted 运单取消作废
  const reason = '';
  const url = 'Order/Order/orderDesertedOp';
  const pack = {
    type,
    reason,
    od_link_ids: odLinkIdList,
  };
  if (!checkSelected(odLinkIdList)) return;
  let popDialog = null;
  const popup = new PopUp(Dialog, {
    classname: 'fn-orderOp', // dialog class
    ref: r => (popDialog = r),
    loadBtnList: ['confirmDeleteOrder'],
    orderType: orderDict,
    dialogInfo: {
      title: `作废${orderDict}`,
      contentHeader: null,
      button: [
        {
          key: 'confirmDeleteOrder',
          children: '确定',
          type: 'primary',
        },
        {
          key: 'cancel',
          children: '取消',
        },
      ],
      actionList: {
        confirmDeleteOrder: (key, data, validateInfo) => {
          if (!validateInfo.isPass) {
            showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
            popDialog && popDialog.ajaxFinish();
            return false;
          }
          // popup.close()
          postDataOperate(
            url,
            Object.assign(pack, data),
            `作废${orderDict}`,
            {},
            () => {
              popDialog && popDialog.ajaxFinish();
              popup && popup.close();
              that.handleRefreshData && that.handleRefreshData();
            },
            'orderDesert',
            {
              failedCallback: () => {
                popDialog && popDialog.ajaxFinish();
                popup && popup.close();
              },
            },
            orderDict,
          );
        },
        cancel: () => {
          popup && popup.close();
        },
      },
    },
    data: {
      reason: {
        type: 'TextArea',
        eventNames: ['onChange'],
        validate: ['require'],
        labelProps: {
          children: '作废原因',
          isRequired: true,
        },
        otherProps: {
          defaultValue: '',
          classname: 'od-reason',
          style: {
            minWidth: 390,
            maxWidth: 390,
            height: 64,
          },
          disabled: false,
          placeholder: '',
        },
      },
    },
    isShow: true,
  }).show();
};
// 取消作废运单
export const orderDisDesert = function (that, rowIndex) {
  let rowData = {};
  let odLinkIdList = [];
  if (typeIs(rowIndex, 'number')) {
    rowData = that.getData()[rowIndex] || {};
    odLinkIdList = [rowData.od_link_id];
  } else {
    odLinkIdList = that.getSelectesKey('od_link_id');
  }
  if (!checkSelected(odLinkIdList)) return;
  const type = 'cancel_deserted'; // do_deserted 运单作废 cancel_deserted 运单取消作废
  const reason = '';
  const url = 'Order/Order/orderDesertedOp';
  const pack = {
    type,
    reason,
    od_link_ids: odLinkIdList,
  };
  if (!checkSelected(odLinkIdList)) return;
  operate(url, pack, '取消作废运单', {}, that.handleRefreshData, 'orderDesertCancel');
};
// 订单转任务
export const reservationTurnToOrder = function (that, ikey, rowIndex) {
  let list = [];
  if (rowIndex !== undefined) {
    // 右键选择
    const selectData = that.getData()[rowIndex];
    list.push(selectData);
  } else {
    // 勾选的
    list = that.getStateSelecteRows();
  }

  if (!list.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  const related = [];
  const notAccept = [];
  const obsoleted = [];
  const deleted = [];
  const rejected = [];
  const success = [];
  const selectedStartPointId = [];
  list.forEach(item => {
    selectedStartPointId.push(item.start_point_id);
    if (item.order_num) return related.push(item.reservation_num);
    if (+item.accept === 0) return notAccept.push(item.reservation_num);
    if (+item.obsolete === 1) return obsoleted.push(item.reservation_num);
    if (+item.del_status) return deleted.push(item.reservation_num);
    if (+item.accept === 2) return rejected.push(item.reservation_num);
    success.push(item);
  });
  const failedDetail = [
    ...related.map(item => ({ msg: '已关联运单或任务：', number: item })),
    ...notAccept.map(item => ({ msg: '未受理，请先受理再操作转单：', number: item })),
    ...obsoleted.map(item => ({ msg: '已作废：', number: item })),
    ...deleted.map(item => ({ msg: '已删除：', number: item })),
    ...rejected.map(item => ({ msg: '已拒绝：', number: item })),
  ];
  const comIdSole = Array.from(new Set(selectedStartPointId)); // 去重
  if (comIdSole.length > 1) {
    showInfo(ERROR, '一次只能操作同一个网点的数据！');
    return false;
  }
  const callback = () => {
    if (success.length > 1) {
      new PopUp(BatchToTaskModal, { onClose: () => that.handleRefreshData(), data: success }).show();
      return;
    }
    if (success[0].business_type === '整车订单') {
      directToTaskCreator({
        reservation_id: success[0].id,
        reservation_num: success[0].reservation_num,
      });
    } else {
      directToOrderCreator({
        reservation_num: success[0].reservation_num,
      });
    }
  };
  opTips({
    orderType: '订单',
    opType: '转单',
    ext: { noSplice: true },
    showSucTips: false,
    className: 'no_to_task',
    resData: {
      errno: success.length ? 0 : -99999,
      res: {
        failed_detail: failedDetail,
        success_id: success,
      },
    },
    continueCallback(ids, cb) {
      cb();
      callback();
    },
    sucCallback: callback,
  });
};
// 创建订单
export const createOrderManage = function (that, btnKey) {
  const is_whole = btnKey === 'add_zc_reservation' ? 1 : 0;
  const titleStr = is_whole ? '整车' : '零担';
  const pageKey = getPageKey().pageConfig.key;
  that.disableMenuButton(btnKey);
  const req = {};
  req.type = is_whole ? 'ZCKF' : 'KD';
  req.company_id = window.company_id;
  const popUpParam = {
    reservationNum: '',
    isShow: true,
    pageType: 'reservation',
    opType: 'add',
    titleName: `新建${titleStr}订单`,
    dragerContainer: that,
    that,
    popName: `operatSlideDrag_${pageKey}`,
    is_whole,
  };
  fetchApi('Reservation/Reservation/edit', {
    method: 'POST',
    body: {
      req,
    },
  }).then(res => {
    that.enableMenuButton(btnKey);
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
      return;
    }
    popUpParam.data = res.res;
    popUpParam.titleName = `新建${titleStr}订单`;
    const dialog = new PopUp(AsyncReservation, popUpParam);
    dialog.show();
  });
};
// 查看订单
export const viewReservation = (that, rowIndex, column, data, obj, isDown = false, cb, activeTab) => {
  console.log(
    that,
    rowIndex,
    column,
    data,
    obj,
    isDown,
    cb,
    activeTab,
    data,
    'that, rowIndex, column, dataArr, obj, isDown = false, cb, activeTab',
  );
  const pageKey = getPageKey().pageConfig.key;
  const tableInfo = that.getReq ? that.getReq() : that.tableInfo;
  const req = {};
  const is_whole = data.business_type === '整车订单';
  const titleStr = is_whole ? '整车' : '零担';
  req.id = data.reservation_id || data.id;
  if (Array.isArray(req.id)) {
    req.id = req.id.join('');
  }
  const dataList = that ? that.getTableList([rowIndex]) : data;
  let odLinkId = dataList[0].reservation_id || dataList[0]['Reservation|reservation_id'];
  req.type = is_whole ? 'ZCKF' : 'KD';
  let orderNum = '';
  if (typeIs(odLinkId, 'array')) {
    const index = data.reservation_num.findIndex(item => item === obj);
    if (index < 0) {
      return;
    }
    orderNum = data.reservation_num[index];
    odLinkId = dataList[0].reservation_id[index];
    req.id = odLinkId;
    req.type = 'ZCKF';
    req.sys_version = 1;
  }
  if (!req.id) {
    req.reservation_num = data.reservation_num;
  }
  req.view = true;
  console.log(data.sys_version, 'data.sys_versiondata.sys_versiondata.sys_version');
  req.sys_version = data.sys_version;
  if (tableInfo.tab && tableInfo.tab.includes('oms')) {
    req.sys_version = 2;
  }
  if (!data.sys_version) {
    req.sys_version = '1';
  }
  const popUpParam = {
    isShow: true,
    pageType: 'reservation',
    opType: 'view',
    dragerContainer: that,
    that,
    type: 'view',
    popName: `operatSlideDrag_${pageKey}`,
    sysVersion: !data.sys_version ? '1' : data.sys_version,
    odBasicId: data.od_basic_id,
    odLinkId: data.od_link_id,
    defaultActiveTab: data.defaultActiveTab,
    is_whole: !data.sys_version ? true : is_whole, // 判断是整车还是零担
    titleName: '新建订单',
  };
  fetchApi('Reservation/Reservation/edit', {
    method: 'POST',
    body: {
      req,
    },
  }).then(res => {
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
      return;
    }
    popUpParam.data = res.res;
    const reservationNum = _.get(res.res, 'reservation_data.reservation_num.value');
    popUpParam.titleName = `查看订单【${reservationNum}】`;
    console.log(popUpParam, 'popUpParampopUpParam');
    const dialog = new PopUp(AsyncReservation, popUpParam);
    dialog.show();
  });
};

export const viewReservationMost = (that, rowIndex, column, dataArr, obj, isDown = false, cb, activeTab) => {
  console.log(that, rowIndex, column, dataArr, obj, isDown, cb, activeTab, '444444');
  // const pageKey = getPageKey().pageConfig.key
  // const tableInfo = that.getReq ? that.getReq() : that.tableInfo
  // const req = {}
  // const is_whole = data.business_type === '整车订单'
  // const titleStr = is_whole ? '整车' : '零担'
  // req.id = data.reservation_id[0] || data.id
  // if (Array.isArray(req.id)) {
  //   req.id = req.id.join('')
  // }
  // if (!req.id) {
  //   req.reservation_num = data.reservation_num
  // }
  // req.type = is_whole ? 'ZCKF' : 'KD'
  // req.view = true
  // req.sys_version = data.sys_version
  // if (tableInfo.tab && tableInfo.tab.includes('oms')) {
  //   req.sys_version = 2
  // }
  // const popUpParam = {
  //   isShow: true,
  //   pageType: 'reservation',
  //   opType: 'view',
  //   dragerContainer: that,
  //   that,
  //   type: 'view',
  //   popName: `operatSlideDrag_${pageKey}`,
  //   sysVersion: data.sys_version,
  //   odBasicId: data.od_basic_id,
  //   odLinkId: data.od_link_id,
  //   defaultActiveTab: data.defaultActiveTab,
  //   is_whole,
  //   titleName: '新建订单'
  // }
  // fetchApi('Reservation/Reservation/edit', {
  //   method: 'POST',
  //   body: {
  //     req
  //   }
  // }).then(res => {
  //   if (res.errno !== 0) {
  //     showInfo(ERROR, res.errmsg)
  //     return
  //   }
  //   popUpParam.data = res.res
  //   const reservationNum = _.get(res.res, 'reservation_data.reservation_num.value')
  //   popUpParam.titleName = `查看订单【${reservationNum}】`
  //   const dialog = new PopUp(AsyncReservation, popUpParam)
  //   dialog.show()
  // })
};
// 处理服务器返回正确的情况
function handleSuccess(res, callback) {
  const resData = res.data || {};
  if (res.type === 'API_SUCCESS' && +resData.errno === 0) {
    callback(resData.res);
  } else {
    showInfo(ERROR, res.message || resData.errmsg || '网络错误');
  }
}

// 显示一个提示, 确认之后再操作
function showConfirm(titleTip, contentTip, callback) {
  new PopUp(ShowInfo, {
    type: INFO,
    content: contentTip,
    title: titleTip,
    maskClosable: false,
    simpleMode: {
      confirm: thisDialog => {
        callback && callback(thisDialog);
      },
      cancel: thisDialog => {
        thisDialog.handleHide();
      },
    },
  }).show();
}

// 显示错误或者详细信息
function showBatchResult(res, opText, that, successCb, errorCb) {
  const resData = res.data || {};
  let successCount = 0;
  const errorTypeMap = {};
  if (res.type === 'API_SUCCESS' && +resData.errno === 0) {
    showInfo(CHECK, `订单${opText}成功`);
    successCb && successCb();
    setTimeout(() => {
      that.handleRefreshData && that.handleRefreshData();
    }, 1000);
  } else {
    that.handleRefreshData && setTimeout(that.handleRefreshData, 1000);
    let failDetailList = resData.res && resData.res.detail;
    if (!Array.isArray(failDetailList)) {
      failDetailList = [];
    }
    if (!failDetailList.length) {
      showMsg(`${opText}失败`, res.message || resData.errmsg || '网络错误');
      return;
    }
    failDetailList.forEach(item => {
      if (+item.status === 0) {
        successCount++;
      } else {
        let obj = errorTypeMap[item.message];
        if (!obj) {
          errorTypeMap[item.message] = obj = { count: 0, ids: [] };
        }
        obj.count++;
        obj.ids.push(item.id);
      }
    });
    const renderSuccessBlock = () => {
      if (successCount) {
        return (
          <figure>
            <figcaption>
              {successCount}条订单{opText}成功
            </figcaption>
          </figure>
        );
      }
      return null;
    };
    const renderErrorBlock = () => {
      const list = [];
      const errorTypes = Object.keys(errorTypeMap);
      for (let i = 0, len = errorTypes.length; i < len; i++) {
        const errorMsg = errorTypes[i];
        const { count } = errorTypeMap[errorMsg];
        const idStr = errorTypeMap[errorMsg].ids.join('，');
        list.push(
          <figure key={i}>
            <figcaption>
              {count}条订单{opText}失败，原因:{errorMsg}，订单号：
            </figcaption>
            <p>{idStr}</p>
          </figure>,
        );
      }
      return <div>{list}</div>;
    };
    const popUp = new PopUp(ShowInfo, {
      type: INFO,
      classname: 'delete_reservation_result',
      title: `${opText}结果`,
      content: (
        <div className="delete_reservation_result_content">
          {renderSuccessBlock()}
          {renderErrorBlock()}
        </div>
      ),
      simpleMode: {
        confirm: thisDialog => {
          thisDialog.handleHide();
          errorCb && errorCb();
        },
      },
    });
    popUp.show();
  }
}

// 受理订单
export const reservationAccept = function (that) {
  const selectedList = that.getSelectesKey('reservation_num');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要受理的订单');
    return;
  }
  const data = {
    reservation_num: selectedList,
  };
  showConfirm('受理订单', '确定要受理所选订单吗?', thisDialog => {
    thisDialog.changeBtnLoadingStatus('confirm', true);
    const postCollectFailData = nums => {
      actions.orderServer.reservationAccept(nums).then(res => {
        thisDialog.handleHide();
        opTips({
          resData: res.data,
          opType: 'accept',
          orderType: '订单',
          continueCallback: (sucId, cb) => {
            cb && cb();
            postCollectFailData({ reservation_num: sucId });
          }, // 成功的回调
          sucCallback: () => {
            setTimeout(that.handleRefreshData, 2000);
          }, // 继续 btn 对应的回调
        });
      });
    };
    postCollectFailData(data);
  });
};
// 报价（对接跨越）
export const reservationQuoted = async (that, btnKey) => {
  const pageKey = getPageKey().pageConfig.key;
  const selectedList = that.getSelectesKey(['reservation_status', 'reservation_num', 'id']);
  if (selectedList.length !== 1) {
    showInfo(ERROR, selectedList.length ? '每次只能操作一条数据！' : '请选择订单！');
    return;
  }
  const selectedInfo = selectedList[0];
  const reservation_status = +selectedInfo.reservation_status;
  // 订单状态是【已取消报价】，操作不了报价操作
  if (reservation_status === 53) {
    showInfo(ERROR, '取消报价后不可重新报价');
    return;
    // 订单状态不是待报价或已报价的，不允许操作报价
  } else if (reservation_status !== 51 && reservation_status !== 52) {
    showInfo(ERROR, '只有待报价和已报价状态的订单才可以操作报价');
    return;
  }

  const popUpParam = {
    reservationNum: selectedInfo.reservation_num,
    id: selectedInfo.id,
    isShow: true,
    pageType: 'reservation',
    opType: 'quoted', // 报价
    type: 'quoted',
    titleName: `订单报价【${selectedInfo.reservation_num}】`,
    dragerContainer: that,
    that,
    popName: `operateSlideDrag_${pageKey}`,
  };
  that.disableMenuButton(btnKey);
  const req = {
    id: selectedInfo.id,
    reservation_num: selectedInfo.reservation_num,
    op_type: 'quoted',
    type: 'KD',
  };
  const url = 'Reservation/Reservation/edit';
  const conf = { method: 'POST', body: { req } };
  const res = await fetchApi(url, conf);
  that.enableMenuButton(btnKey);
  if (+res.errno !== 0) {
    res.errmsg && showInfo(ERROR, res.errmsg);
    return;
  }
  popUpParam.data = res.res;
  // 侧拉弹框
  new PopUp(AsyncReservation, popUpParam).show();
};
// 取消受理订单
export const reservationCancelAccept = (that, btnKey, rowIndex) => {
  let selectedList;
  if (typeIs(rowIndex, 'number')) {
    selectedList = that.getData()[rowIndex];
    selectedList = [selectedList].map(item => ({ reservation_num: item.reservation_num, id: item.id }));
  } else {
    selectedList = that.getSelectesKey(['reservation_num', 'id']);
  }
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  const dataMap = {};
  selectedList.forEach(item => {
    dataMap[item.id] = item;
    dataMap[item.reservation_num] = item;
  });
  that.disableMenuButton(btnKey);
  const postCollectFailData = nums => {
    actions.orderServer.reservationCancelAccept(nums).then(res => {
      that.enableMenuButton(btnKey);
      opTips({
        resData: res.data,
        opType: 'reservationCancelAccept',
        orderType: '订单',
        continueCallback: (sucId, cb) => {
          cb && cb();
          postCollectFailData(sucId.map(item => dataMap[item]));
        }, // 成功的回调
        sucCallback: () => {
          setTimeout(that.handleRefreshData, 2000);
        }, // 继续 btn 对应的回调
      });
    });
  };
  postCollectFailData(selectedList);
};
// 取消报价（对接跨越）
export const reservationCancelQuoted = async (that, btnKey) => {
  const selectedList = that.getSelectesKey(['id', 'reservation_num']);
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的订单！');
    return;
  }
  const popContent = (
    <div className="quoted-title">
      <p className="big">确定取消报价吗？</p>
      <p className="small">订单取消报价后无法重新报价。如需修改报价不要点击取消报价可直接点击“报价”按钮更新报价。</p>
    </div>
  );
  const popContentNotice = (
    <div className="quoted-content">
      <div className="warning">请注意：</div>
      <div className="reason">
        <div className="item">
          <em>●</em>已中标的订单取消报价有可能会产生相应费用
        </div>
        <div className="item">
          <em>●</em>已关联运单的订单取消报价后不会影响运单状态变化
        </div>
      </div>
    </div>
  );
  if (!(await confirm(INFO, popContent, {}, '操作确认', popContentNotice, null, 'cancel-quoted'))) return;
  const id = selectedList.map(item => item.id);
  const req = {
    id,
    is_check: 1,
  };
  const url = 'Reservation/Reservation/cancelQuoted';
  // 点击继续的请求
  const continueRequire = async ids => {
    req.id = ids.length ? ids : id;
    req.is_check = 0;
    const conf = { method: 'POST', body: { req } };
    that.disableMenuButton(btnKey);
    const res = await fetchApi(url, conf);
    that.enableMenuButton(btnKey);
    if (+res.errno !== 0) {
      res.errmsg && showInfo(ERROR, res.errmsg);
      return;
    }
    res.errmsg && showInfo(CHECK, res.errmsg);
    setTimeout(that.handleRefreshData, 2000);
  };
  // 第一次请求，判断所选的是否都可执行
  const checkConf = { method: 'POST', body: { req } };
  that.disableMenuButton(btnKey);
  const checkRes = await fetchApi(url, checkConf);
  that.enableMenuButton(btnKey);
  console.log('check_res', checkRes);
  opTips({
    resData: checkRes,
    opType: 'reservationCancelQuoted',
    orderType: '订单',
    continueCallback: (sucId, cb) => {
      // 继续 btn 对应的回调
      cb && cb();
      continueRequire(sucId);
    },
    sucCallback: () => {
      // 成功的回调
      setTimeout(that.handleRefreshData, 2000);
    },
  });
};
// 拒绝订单
export const reservationRefuse = that => {
  const selectedList = that.getSelectesKey('reservation_num');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要拒绝的订单');
    return;
  }
  const popup = new PopUp(Dialog, {
    classname: 'fn-orderOp cardform-no-border', // dialog class
    loadBtnList: ['confirmRefuseReservation'],
    dialogInfo: {
      title: '取消受理订单',
      contentHeader: null,
      button: [
        {
          key: 'confirmRefuseReservation',
          children: '确定',
          type: 'primary',
        },
        {
          key: 'cancel',
          children: '取消',
        },
      ],
      actionList: {
        confirmRefuseReservation: (key, data, validateInfo, finish) => {
          if (!validateInfo.isPass) {
            showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
            if (typeof finish === 'function') finish();
            return false;
          }
          const postCollectFailData = nums => {
            const reqData = {
              reservation_num: nums,
              reason: data.reason,
            };
            actions.orderServer.reservationRefuse(reqData).then(res => {
              popup.close();
              opTips({
                resData: res.data,
                opType: 'refuse',
                orderType: '订单',
                continueCallback: (sucId, cb) => {
                  cb && cb();
                  postCollectFailData(sucId);
                }, // 成功的回调
                sucCallback: () => {
                  setTimeout(that.handleRefreshData, 2000);
                }, // 继续 btn 对应的回调
              });
            });
          };
          postCollectFailData(selectedList);
        },
        cancel: () => {
          popup.close();
        },
      },
    },
    data: {
      reason: {
        type: 'TextArea',
        eventNames: ['onChange'],
        validate: ['require'],
        labelProps: {
          children: '拒绝原因: ',
          isRequired: true,
        },
        otherProps: {
          defaultValue: '',
          classname: 'od-reason',
          style: {
            minWidth: 390,
            maxWidth: 390,
            height: 64,
          },
          disabled: false,
          placeholder: '',
        },
      },
    },
    isShow: true,
  }).show();
};
// 转运单
// export const reservationTurnToOrder = function (that, ikey, rowIndex) {
//   let list = []
//   if (rowIndex !== undefined) {
//     // 右键选择
//     const selectData = that.getData()[rowIndex]
//     list.push(selectData)
//   } else {
//     // 勾选的
//     list = that.getStateSelecteRows()
//   }
//   if (!list.length) {
//     showInfo(ERROR, '请选择订单')
//     return
//   }
//   const related = []
//   const notAccept = []
//   const obsoleted = []
//   const deleted = []
//   const rejected = []
//   const success = []
//   const selectedStartPointId = []
//   list.forEach(item => {
//     selectedStartPointId.push(item.start_point_id)
//     if (item.od_basic_id) return related.push(item.reservation_num)
//     if (+item.accept === 0) return notAccept.push(item.reservation_num)
//     if (+item.obsolete === 1) return obsoleted.push(item.reservation_num)
//     if (+item.del_status) return deleted.push(item.reservation_num)
//     if (+item.accept === 2) return rejected.push(item.reservation_num)
//     success.push(item)
//   })
//   const comIdSole = Array.from(new Set(selectedStartPointId)) // 去重
//   if (comIdSole.length > 1) {
//     showInfo(ERROR, '一次只能操作同一个网点的数据！')
//     return false
//   }
//   const failedDetail = [
//     ...related.map(item => ({ msg: '已关联运单：', number: item })),
//     ...notAccept.map(item => ({ msg: '未受理，请先受理再操作转运单：', number: item })),
//     ...obsoleted.map(item => ({ msg: '已作废：', number: item })),
//     ...deleted.map(item => ({ msg: '已删除：', number: item })),
//     ...rejected.map(item => ({ msg: '已拒绝：', number: item })),
//   ]
//   const directToOrder = () => browserHistory.pushWithQuery({
//     pathname: '/Order/coInfo',
//     query: {
//       coid: Date.now(),
//       reservation_num: success[0].reservation_num
//     }
//   })
//   const callback = () => {
//     if (success.length > 1) {
//       new PopUp(BatchR2OModal, { onClose: () => that.handleRefreshData(), data: success }).show()
//       return
//     }
//     directToOrder()
//   }
//   opTips({
//     orderType: '订单',
//     opType: '转运单',
//     ext: { noSplice: true },
//     showSucTips: false,
//     resData: {
//       errno: success.length ? 0 : -99999,
//       res: {
//         failed_detail: failedDetail,
//         success_id: success,
//       }
//     },
//     continueCallback(ids, cb) {
//       cb()
//       callback()
//     },
//     sucCallback: callback
//   })
// }
// 关联运单/任务
export const reservationRelateOrder = async function (that, btnKey, rowIndex) {
  console.log(66666);

  const pageKey = getPageKey().pageConfig.key;
  let selectedList = [];
  let reservationIds = [];
  if (rowIndex !== undefined) {
    // 右键选择
    const selectData = that.getData()[rowIndex];
    selectedList.push(selectData);
  } else {
    // 勾选的
    selectedList = that.getSelectesKey([
      'reservation_num',
      'id',
      'line_name',
      'batch_type',
      'project_id',
      'business_type',
      'order_num',
    ]);
    reservationIds = that.getSelectesKey('id');
  }

  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }

  const orderType = selectedList.filter(item => item.business_type !== '整车订单'); // 零担
  if (orderType.length === selectedList.length && orderType.length > 1) {
    // 表示只有零担且勾选了多条
    showInfo(ERROR, '一次只能勾选一条数据');
    return;
  }
  if (selectedList.length > 1 && orderType.length > 0) {
    await confirmWithReasonSync({
      tips: '只有整车订单可以操作多个订单关联单号', // 顶部提示语
      noticeTitle: '',
      reason: '', // 具体原因
      btnText: { confirm: '关闭' },
      btns: { confirm: 1, cancel: 0 },
    });
    return;
  }
  const isWhole = selectedList[0].business_type === '整车订单'; // 是否为整车订单关联任务
  const isWholeStr = isWhole ? '任务' : '运单';
  if (selectedList.length > 1 && orderType === 0) {
    showInfo(ERROR, `只能选择一个订单关联${isWholeStr}`);
    return;
  }
  if (selectedList.length > 30 && selectedList.length >= 1 && orderType.length === 0) {
    showInfo(WARN, '最多操作30个订单进行关联单号');
    return;
  }
  const isHasOrderNum = selectedList.filter(item => item.order_num);
  if (isHasOrderNum.length !== 0) {
    await confirmWithReasonSync({
      tips: '已关联任务的整车订单，不可重复关联', // 顶部提示语
      noticeTitle: '',
      reason: '', // 具体原因
      btnText: { confirm: '关闭' },
      btns: { confirm: 1, cancel: 0 },
    });
    return;
  }
  const popName = 'reservationRelateOrder';
  const popUpParam = {
    reservationNum: selectedList[0].reservation_num,
    reservationId: selectedList[0].id,
    reservationIds,
    line_name: selectedList[0].line_name,
    batch_type: selectedList[0].batch_type,
    project_id: selectedList[0].project_id,
    isShow: true,
    pageType: popName,
    titleName: `关联${isWholeStr}`,
    dragerContainer: that,
    that,
    popName: `operatSlideDrag_${pageKey}`,
    isWhole,
    isWholeStr,
  };
  const dialog = new PopUp(orderListPopup, popUpParam);
  dialog.show();
};
// 订单作废
export const reservationObsolete = (that, ikey, rowIndex) => {
  let selectedList;
  if (typeIs(rowIndex, 'number')) {
    selectedList = that.getData()[rowIndex];
    selectedList = [selectedList].map(item => ({ reservation_num: item.reservation_num, id: item.id }));
  } else {
    selectedList = that.getSelectesKey(['reservation_num', 'id']);
  }
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  const dataMap = {};
  selectedList.forEach(item => {
    dataMap[item.id] = item;
    dataMap[item.reservation_num] = item;
  });
  const popup = new PopUp(Dialog, {
    classname: 'fn-orderOp cardform-no-border', // dialog class
    loadBtnList: ['confirmObsoleteReservation'],
    dialogInfo: {
      title: '作废订单',
      contentHeader: null,
      button: [
        {
          key: 'confirmObsoleteReservation',
          children: '确定',
          type: 'primary',
        },
        {
          key: 'cancel',
          children: '取消',
        },
      ],
      actionList: {
        confirmObsoleteReservation: (key, data, validateInfo, finish) => {
          if (!validateInfo.isPass) {
            showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
            if (typeof finish === 'function') finish();
            return false;
          }
          const postCollectFailData = nums => {
            actions.orderServer.reservationObsolete(nums, data.reason).then(res => {
              popup.close();
              opTips({
                resData: res.data,
                opType: 'obsolete',
                orderType: '订单',
                continueCallback: (sucId, cb) => {
                  cb && cb();
                  postCollectFailData(sucId.map(item => dataMap[item]));
                }, // 成功的回调
                sucCallback: () => {
                  setTimeout(that.handleRefreshData, 2000);
                }, // 继续 btn 对应的回调
              });
            });
          };
          postCollectFailData(selectedList);
        },
        cancel: () => {
          popup.close();
        },
      },
    },
    data: {
      reason: {
        type: 'TextArea',
        eventNames: ['onChange'],
        validate: ['require'],
        labelProps: {
          children: '作废原因',
          isRequired: true,
        },
        otherProps: {
          defaultValue: '',
          classname: 'od-reason',
          maxLength: 100,
          style: {
            minWidth: 390,
            maxWidth: 390,
            height: 64,
          },
          disabled: false,
          placeholder: '',
        },
      },
    },
    isShow: true,
  }).show();
};
const reservationObsoleteOperate = (that, row, col, theRowData) => {
  const selectedList = [theRowData];
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  const popup = new PopUp(Dialog, {
    classname: 'fn-orderOp', // dialog class
    loadBtnList: ['confirmObsoleteReservation'],
    dialogInfo: {
      title: '作废订单',
      contentHeader: null,
      button: [
        {
          key: 'confirmObsoleteReservation',
          children: '确定',
          type: 'primary',
        },
        {
          key: 'cancel',
          children: '取消',
        },
      ],
      actionList: {
        confirmObsoleteReservation: (key, data, validateInfo, finish) => {
          if (!validateInfo.isPass) {
            showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
            if (typeof finish === 'function') finish();
            return false;
          }
          actions.orderServer.reservationObsolete(selectedList, data.reason).then(res => {
            popup.close();
            showBatchResult(res, '作废', that);
          });
        },
        cancel: () => {
          popup.close();
        },
      },
    },
    data: {
      reason: {
        type: 'TextArea',
        eventNames: ['onChange'],
        validate: ['require'],
        labelProps: {
          children: '作废原因',
          isRequired: true,
        },
        otherProps: {
          defaultValue: '',
          classname: 'od-reason',
          maxLength: 100,
          style: {
            minWidth: 390,
            maxWidth: 390,
            height: 64,
          },
          disabled: false,
          placeholder: '',
        },
      },
    },
    isShow: true,
  }).show();
};
// 订单取消作废
export const reservationCancelObsolete = (that, ikey, rowIndex) => {
  let selectedList;
  if (typeIs(rowIndex, 'number')) {
    selectedList = that.getData()[rowIndex];
    selectedList = [selectedList].map(item => ({ reservation_num: item.reservation_num, id: item.id }));
  } else {
    selectedList = that.getSelectesKey(['reservation_num', 'id']);
  }
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  const dataMap = {};
  selectedList.forEach(item => {
    dataMap[item.id] = item;
    dataMap[item.reservation_num] = item;
  });
  that.disableMenuButton('cancel_obsolete');
  const postCollectFailData = nums => {
    actions.orderServer.reservationCancelObsolete(nums).then(res => {
      that.enableMenuButton('cancel_obsolete');
      opTips({
        resData: res.data,
        opType: 'cancelObsolete',
        orderType: '订单',
        continueCallback: (sucId, cb) => {
          cb && cb();
          postCollectFailData(sucId.map(item => dataMap[item]));
        }, // 成功的回调
        sucCallback: () => {
          setTimeout(that.handleRefreshData, 2000);
        }, // 继续 btn 对应的回调
      });
    });
  };
  postCollectFailData(selectedList);
};
const reservationCancelObsoleteOperate = (that, row, col, theRowData) => {
  const selectedList = [theRowData];
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  that.disableMenuButton('cancel_obsolete');
  actions.orderServer.reservationCancelObsolete(selectedList).then(res => {
    that.enableMenuButton('cancel_obsolete');
    showBatchResult(res, '取消作废', that);
  });
};
// 揽收失败
const collectFail = that => {
  const selectedList = that.getSelectesKey('reservation_num');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  const popup = new PopUp(Dialog, {
    classname: 'fn-orderOp cardform-no-border', // dialog class
    loadBtnList: ['confirmCollectFail'],
    dialogInfo: {
      title: '揽收失败',
      contentHeader: null,
      button: [
        {
          key: 'confirmCollectFail',
          children: '确定',
          type: 'primary',
        },
        {
          key: 'cancel',
          children: '取消',
        },
      ],
      actionList: {
        confirmCollectFail: (key, data, validateInfo, finish) => {
          if (!validateInfo.isPass) {
            showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
            if (typeof finish === 'function') finish();
            return false;
          }
          const postCollectFailData = nums => {
            actions.orderServer.reservationCollectFail(nums, data.reason).then(res => {
              popup && popup.close();
              opTips({
                resData: res.data,
                opType: 'collectFail',
                orderType: '订单',
                continueCallback: (sucId, cb) => {
                  cb && cb();
                  postCollectFailData(sucId);
                }, // 成功的回调
                sucCallback: () => {
                  setTimeout(that.handleRefreshData, 2000);
                }, // 继续 btn 对应的回调
              });
            });
          };
          postCollectFailData(selectedList);
        },
        cancel: () => {
          popup.close();
        },
      },
    },
    data: {
      reason: {
        type: 'TextArea',
        eventNames: ['onChange'],
        validate: ['require'],
        labelProps: {
          children: '揽收失败原因',
          isRequired: true,
        },
        otherProps: {
          defaultValue: '',
          classname: 'od-reason',
          maxLength: 100,
          style: {
            minWidth: 390,
            maxWidth: 390,
            height: 64,
          },
          disabled: false,
          placeholder: '',
        },
      },
    },
    isShow: true,
  }).show();
};
const assignReservation = (that, buttonKey) => {
  const data = that.getSelectesKey('id');
  that.disableMenuButton(buttonKey);
  fetchApi('Reservation/Reservation/assignReservationInfos', {
    method: 'POST',
    body: {
      req: {
        id: data,
      },
    },
  }).then(res => {
    that.enableMenuButton(buttonKey);
    const open = (sucIds, cb) => {
      cb && cb();
      const urlData = {
        controller: 'Reservation',
        action: 'load',
        query: {
          fromPickClip: isFromPickClip(that),
          tabName: '指派',
        },
      };
      const directInfo = { pickOrderInfo: res.res, sendReq: false, comId: window.company_id };
      actions.api.direct(urlData, 'directInfo', directInfo);
    };
    opTips({
      resData: res,
      opType: '指派',
      orderType: '订单',
      sucCallback: open, // 成功的回调
      continueCallback: open, // 继续 btn 对应的回调
      showSucTips: false,
    });
  });
};
// 订单删除
export const reservationDelete = (that, btnKey, rowIndex) => {
  let selectedList;
  if (typeIs(rowIndex, 'number')) {
    selectedList = that.getData()[rowIndex];
    selectedList = [selectedList].map(item => ({ reservation_num: item.reservation_num, id: item.id }));
  } else {
    selectedList = that.getSelectesKey(['reservation_num', 'id']);
  }
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  showConfirm('删除订单', '确定要删除所选订单吗?', thisDialog => {
    thisDialog.changeBtnLoadingStatus('confirm', true);
    const dataMap = {};
    selectedList.forEach(item => {
      dataMap[item.id] = item;
      dataMap[item.reservation_num] = item;
    });
    that.disableMenuButton(btnKey);
    const postCollectFailData = nums => {
      actions.orderServer.reservationDelete(nums).then(res => {
        that.enableMenuButton(btnKey);
        thisDialog.handleHide();
        opTips({
          resData: res.data,
          opType: 'delete',
          orderType: '订单',
          continueCallback: (sucId, cb) => {
            cb && cb();
            postCollectFailData(sucId.map(item => dataMap[item]));
          }, // 成功的回调
          sucCallback: () => {
            setTimeout(that.handleRefreshData, 2000);
          }, // 继续 btn 对应的回调
        });
      });
    };
    postCollectFailData(selectedList);
  });
};
// 修改订单
export const editReservation = (that, ikey, rowIndex) => {
  const pageKey = getPageKey().pageConfig.key;
  let selectedList;
  if (typeIs(rowIndex, 'number')) {
    const rowData = that.getData()[rowIndex] || {};
    selectedList = [rowData];
  } else {
    selectedList = that.getSelectesKey(['reservation_num', 'id', 'business_type']);
  }
  if (!selectedList.length) {
    showInfo(ERROR, '请选择订单');
    return;
  }
  if (selectedList.length > 1) {
    showInfo(ERROR, '只能选择一个订单进行修改');
    return;
  }
  that.disableMenuButton('edit_reservation');
  const curReq = that.getReq();
  const _tab = curReq.tab;
  const req = {};
  const is_whole = selectedList[0].business_type === '整车订单';
  const titleStr = is_whole ? '整车' : '零担';
  req.reservation_num = selectedList[0].reservation_num;
  req.id = selectedList[0].id;
  req.company_id = selectedList[0].start_point_id || window.company_id;
  req.type = is_whole ? 'ZCKF' : 'KD';
  const popUpParam = {
    reservationNum: selectedList[0].reservation_num,
    id: selectedList[0].id,
    isShow: true,
    pageType: 'reservation',
    opType: 'edit',
    titleName: `修改订单【${selectedList[0].reservation_num}】`,
    dragerContainer: that,
    that,
    popName: `operatSlideDrag_${pageKey}`,
    is_whole,
  };
  fetchApi('Reservation/Reservation/edit', {
    method: 'POST',
    body: {
      req,
    },
  }).then(res => {
    that.enableMenuButton('edit_reservation');
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
      return;
    }
    popUpParam.data = res.res;
    const dialog = new PopUp(AsyncReservation, popUpParam);
    dialog.show();
  });
};

// 批量修改订单
export const batchEditReservation = (that, btnKey) => {
  const selectedList = that.getSelectesKey(['reservation_num', 'id', 'reservation_status', 'start_point_id']) || [];
  if (!selectedList.length) {
    showInfo(ERROR, '至少勾选一条数据，请检查后重新选择');
    return false;
  }
  const selectedStartPointId = [];
  selectedList.forEach(item => {
    selectedStartPointId.push(item.start_point_id);
  });
  const comIdSole = Array.from(new Set(selectedStartPointId)); // 去重
  if (comIdSole.length > 1) {
    showInfo(ERROR, '订单出发网点不一致，请检查后重新选择');
    return false;
  }
  const pageKey = getPageKey().pageConfig.key;
  that.disableMenuButton(btnKey);

  const req = {};
  req.company_id = window.company_id;
  const popUpParam = {
    reservationNum: '',
    isShow: true,
    pageType: 'reservation',
    opType: 'batchEdit',
    titleName: '批量修改订单',
    dragerContainer: that,
    that,
    popName: `operatSlideDrag_${pageKey}`,
    selectedList,
  };
  fetchApi('Reservation/Reservation/edit', {
    method: 'POST',
    body: {
      req,
    },
  }).then(res => {
    that.enableMenuButton(btnKey);
    if (res.errno !== 0) {
      showInfo(ERROR, res.errmsg);
      return;
    }
    popUpParam.data = res.res;
    popUpParam.enums = res.res.enum;
    const dialog = new PopUp(batchEditReservations, popUpParam);
    dialog.show();
  });
};

export const cancelDeliveryDp = async (that, btnKey) => {
  const selectedList = that.getSelectesKey('id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return false;
  }
  const reason = (
    <p>
      <span>1、对应订单的提配状态将变为：未送配</span>
      <br />
      <span>2、送配司机、送配司机手机、提配派单时间将清空</span>
    </p>
  );
  const confrim = await confirmWithReasonSync({
    iconType: WARN, // icon
    tips: '您确定要对所选订单取消送配派单吗', // 顶部提示语
    noticeTitle: '取消后：',
    reason, // 具体原因
    // onConfirm = (cb) => { cb() },
    // onCancel = () => { },
    // onClose,
    btns: { confirm: 1, cancel: 1 },
  });
  if (confrim) {
    const postFormData = data => {
      that.disableMenuButton(btnKey);
      fetchApi('Reservation/ReservationDp/opReservationDp', {
        method: 'POST',
        body: {
          req: {
            type: 'delivery',
            op_type: 'cancel',
            ids: data,
          },
        },
      }).then(res => {
        that.enableMenuButton(btnKey);
        opTips({
          orderType: '订单',
          opType: 'cancelDeliveryDp',
          resData: res,
          continueCallback(ids, cb) {
            cb();
            postFormData(ids);
          },
          sucCallback: () => {
            setTimeout(that.handleRefreshData, 2000);
          },
        });
      });
    };
    postFormData(selectedList);
  }
};
export const cancelPickupDp = async (that, btnKey) => {
  const selectedList = that.getSelectesKey('id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return false;
  }
  const reason = (
    <p>
      <span>1、对应订单的提配状态将变为：未提配</span>
      <br />
      <span>2、提配司机、提配司机手机、提配派单时间将清空</span>
    </p>
  );
  const confrim = await confirmWithReasonSync({
    iconType: WARN, // icon
    tips: '您确定要对所选订单取消送配派单吗', // 顶部提示语
    noticeTitle: '取消后：',
    reason, // 具体原因
    btns: { confirm: 1, cancel: 1 },
  });
  if (confrim) {
    const postFormData = data => {
      that.disableMenuButton(btnKey);
      fetchApi('Reservation/ReservationDp/opReservationDp', {
        method: 'POST',
        body: {
          req: {
            type: 'pickup',
            op_type: 'cancel',
            ids: data,
          },
        },
      }).then(res => {
        that.enableMenuButton(btnKey);
        opTips({
          orderType: '订单',
          opType: 'cancelPickupDp',
          resData: res,
          continueCallback(ids, cb) {
            cb();
            postFormData(ids);
          },
          sucCallback: () => {
            setTimeout(that.handleRefreshData, 2000);
          },
        });
      });
    };
    postFormData(selectedList);
  }
};
export const deliveryDispatch = (that, btnKey) => {
  const selectedList = that.getSelectesKey('id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return false;
  }
  const postFormData = data => {
    that.disableMenuButton(btnKey);
    fetchApi('Reservation/ReservationDp/checkReservationDp', {
      method: 'POST',
      body: {
        req: {
          type: 'delivery',
          op_type: 'add',
          ids: data,
        },
      },
    }).then(res => {
      that.enableMenuButton(btnKey);
      opTips({
        orderType: '订单',
        opType: 'delivery',
        resData: res,
        showSucTips: false,
        continueCallback(ids, cb) {
          cb();
          postFormData(ids);
        },
        sucCallback: () => {
          browserHistory.pushWithQuery({
            pathname: '/Reservation/dpCenter',
            query: {
              ...res.res,
              type: 'delivery',
              tabName: '送配派单司机调度中心',
              pathname: window.location.pathname,
            },
          });
        },
      });
    });
  };
  postFormData(selectedList);
};
export const pickupDispatch = (that, btnKey) => {
  const selectedList = that.getSelectesKey('id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return false;
  }
  const postFormData = data => {
    that.disableMenuButton(btnKey);
    fetchApi('Reservation/ReservationDp/checkReservationDp', {
      method: 'POST',
      body: {
        req: {
          type: 'pickup',
          op_type: 'add',
          ids: data,
        },
      },
    }).then(res => {
      that.enableMenuButton(btnKey);
      opTips({
        orderType: '订单',
        opType: 'pickupDp',
        resData: res,
        showSucTips: false,
        continueCallback(ids, cb) {
          cb();
          postFormData(ids);
        },
        sucCallback: () => {
          browserHistory.pushWithQuery({
            pathname: '/Reservation/dpCenter',
            query: {
              ...res.res,
              type: 'pickup',
              tabName: '提配派单司机调度中心',
              pathname: window.location.pathname,
            },
          });
        },
      });
    });
  };
  postFormData(selectedList);
};

// 异动管理列表添加异动
export const createMove = async (that, btnKey) => {
  const pageKey = getPageKey().pageConfig.key;
  // 判断来源，如果来至运单列表
  const curReq = that.getReq();
  const _tab = curReq.tab;
  let moveType;
  let titleName;
  switch (_tab) {
    case 'point_trans_move':
      moveType = 1;
      titleName = '添加网点中转异动';
      break;
    case 'point_trans_rcp_move':
      titleName = '添加网点中转异动';
      moveType = 2;
      break;
    default:
      moveType = 0;
      titleName = '添加异动';
  }
  if (_tab === 'move' || _tab === 'point_trans_move' || _tab === 'point_trans_rcp_move') {
    that.disableMenuButton(btnKey);
    const popUpParam = {
      popName: `operatSlideDrag_${pageKey}`,
      action: 'add',
      moveType,
      titleName,
      fromWhere: 'move',
      popContainer: that,
      callback: that.handleRefreshData,
    };
    new PopUp(abnormalMF, popUpParam).show();
    that.enableMenuButton(btnKey);
  } else {
    let selectedList;
    try {
      ({ od_link_id: selectedList } = await preArrivalCheck(that, '添加异动', ['od_link_id']));
    } catch (error) {
      return;
    }
    // let selectedList = that.getSelectesKey('od_link_id')
    if (!selectedList.length) {
      showInfo(ERROR, '请选择一条运单');
      return;
    }
    if (selectedList.length > 1) {
      showInfo(ERROR, '一次只能操作一条数据');
      return;
    }
    that.disableMenuButton(btnKey);
    fetchApi('Order/Move/onMoveBuild', {
      method: 'POST',
      body: { req: { od_link_id: selectedList, type: 'add', move_type: moveType } },
    })
      .then(res => {
        if (+res.errno === 0) {
          const popUpParam = {
            popName: `operatSlideDrag_${pageKey}`,
            action: 'add',
            moveType,
            titleName,
            odLinkId: selectedList[0],
            formData: res.res,
            popContainer: that,
            callback: that.handleRefreshData,
          };
          new PopUp(abnormalMF, popUpParam).show();
        } else {
          showInfo(ERROR, res.errmsg || '网络错误');
        }
        that.enableMenuButton(btnKey);
      })
      .catch(() => that.enableMenuButton('update_move'));
  }
};
// 修改异动
export const updateMove = that => {
  const pageKey = getPageKey().pageConfig.key;
  const selectedList = that.getSelectesKey('od_link_id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择一条异动');
    return;
  }
  if (selectedList.length > 1) {
    showInfo(ERROR, '一次只能操作一条数据');
    return;
  }
  const curReq = that.getReq();
  const _tab = curReq.tab;
  let moveType;
  let titleName;
  switch (_tab) {
    case 'point_trans_move':
      moveType = 1;
      titleName = '修改网点中转异动';
      break;
    case 'point_trans_rcp_move':
      moveType = 2;
      titleName = '修改网点中转异动';
      break;
    default:
      moveType = 0;
      titleName = '修改异动';
  }
  that.disableMenuButton('update_move');
  // let param = {
  //   od_link_id: selectedList,
  //   action: 'update'
  // }
  fetchApi('Order/Move/onMoveBuild', {
    method: 'POST',
    body: { req: { od_link_id: selectedList, type: 'upd', move_type: moveType } },
  })
    .then(res => {
      if (+res.errno === 0) {
        const popUpParam = {
          popName: `operatSlideDrag_${pageKey}`,
          action: 'upd',
          titleName,
          odLinkId: selectedList[0],
          formData: res.res,
          popContainer: that,
          callback: that.handleRefreshData,
          moveType,
        };
        new PopUp(abnormalMF, popUpParam).show();
      } else {
        showInfo(ERROR, res.errmsg || '网络错误');
      }
      that.enableMenuButton('update_move');
    })
    .catch(() => that.enableMenuButton('update_move'));
};
export const checkMove = that => {
  const selectedList = that.getSelectesKey('od_link_id');
  if (!selectedList.length) {
    showInfo(ERROR, '请至少选中一行数据');
    return;
  } else if (selectedList.length > 100) {
    showInfo(ERROR, '一次最多操作100条运单！');
    return;
  }
  const curReq = that.getReq();
  const _tab = curReq.tab;
  let moveType;
  switch (_tab) {
    case 'point_trans_move':
      moveType = 1;
      break;
    case 'point_trans_rcp_move':
      moveType = 2;
      break;
    default:
      moveType = 0;
  }
  that.disableMenuButton('check_move');
  let reqData = {
    od_link_id: selectedList,
    is_check: 1,
    move_type: moveType,
  };
  actions.orderServer.checkMove(reqData).then(res => {
    that.enableMenuButton('check_move');
    // 有不能进行审核的数据，进行提示
    handleResponse({ operation: '审核', isCheck: 1 }, res, (succDt, closeResTip) => {
      closeResTip && closeResTip();
      // 点击继续后弹出审核意见框
      const auditTypeData = [
        {
          label: '通过',
          value: 'pass',
          name: 'auditRigTypeData',
          direction: 'right',
        },
        {
          label: '拒绝',
          value: 'reject',
          name: 'auditRigTypeData',
          direction: 'right',
        },
      ];
      const popup = new PopUp(Dialog, {
        classname: 'move_audit', // dialog class
        loadBtnList: ['moveAuditConfirm'],
        dialogInfo: {
          title: '异动审批',
          contentHeader: null,
          button: [
            {
              key: 'moveAuditConfirm',
              children: '确定',
              type: 'primary',
            },
            {
              key: 'cancel',
              children: '取消',
            },
          ],
          actionList: {
            moveAuditConfirm: (key, data, validateInfo) => {
              if (!validateInfo.isPass) {
                showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
                return false;
              }
              reqData = {
                od_link_id: succDt,
                crease_check_remark: data.reason,
                operate: data.auditType,
                is_check: 0,
                move_type: moveType,
              };
              actions.orderServer.checkMove(reqData).then(dores => {
                popup.close();
                handleResponse({ operation: '审核' }, dores, () => {
                  setTimeout(() => {
                    that.handleRefreshData && that.handleRefreshData();
                  }, 1000);
                });
              });
            },
            cancel: () => {
              popup.close();
            },
          },
        },
        data: {
          auditType: {
            type: 'Radio',
            eventNames: ['onChange'],
            validate: ['require'],
            labelProps: {
              children: '审批结果：',
              isRequired: true,
            },
            otherProps: {
              classname: 'two_radio',
              data: auditTypeData,
              value: 'pass',
            },
          },
          reason: {
            type: 'TextArea',
            eventNames: ['onChange'],
            otherProps: {
              classname: 'audit_reason',
              disabled: false,
              defaultValue: '',
              placeholder: '',
            },
          },
        },
        isShow: true,
      }).show();
    });
  });
};
// 取消异动审核
export const cancelCheckMove = that => {
  const selectedList = that.getSelectesKey('od_link_id');
  if (!selectedList.length) {
    showInfo(ERROR, '请至少选中一行数据');
    return;
  } else if (selectedList.length > 100) {
    showInfo(ERROR, '一次最多操作100条运单！');
    return;
  }
  const curReq = that.getReq();
  const _tab = curReq.tab;
  let moveType;
  switch (_tab) {
    case 'point_trans_move':
      moveType = 1;
      break;
    case 'point_trans_rcp_move':
      moveType = 2;
      break;
    default:
      moveType = 0;
  }
  showConfirm('取消审核', '您确定要取消审核所选异动吗?', thisDialog => {
    thisDialog.changeBtnLoadingStatus('confirm', true);
    let reqData = {
      od_link_id: selectedList,
      is_check: 1,
      move_type: moveType,
    };
    actions.orderServer.cancelcheckMove(reqData).then(res => {
      thisDialog.changeBtnLoadingStatus('confirm', false);
      thisDialog.handleHide();
      handleResponse({ operation: '取消审核', isCheck: 1 }, res, (succDt, closeResTip) => {
        if (!succDt || succDt.length === 0 || succDt.length === selectedList.length) {
          showInfo(INFO, '取消审核成功');
          setTimeout(() => {
            that.handleRefreshData && that.handleRefreshData();
          }, 1000);
          return;
        }
        reqData = {
          od_link_id: succDt,
          move_type: moveType,
        };
        actions.orderServer.cancelcheckMove(reqData).then(dores => {
          closeResTip && closeResTip();
          handleResponse({ operation: '取消审核' }, dores, () => {
            setTimeout(() => {
              that.handleRefreshData && that.handleRefreshData();
            }, 1000);
          });
        });
      });
    });
  });
};
// 删除
export const deleteMove = that => {
  const selectedList = that.getSelectesKey('od_link_id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选中一行数据');
    return;
  }
  if (selectedList.length > 1) {
    showInfo(ERROR, '只能操作一行');
    return;
  }
  const curReq = that.getReq();
  const _tab = curReq.tab;
  let moveType;
  switch (_tab) {
    case 'point_trans_move':
      moveType = 1;
      break;
    case 'point_trans_rcp_move':
      moveType = 2;
      break;
    default:
      moveType = 0;
  }
  showConfirm('删除单据', '您确定要删除所选单据吗?', thisDialog => {
    thisDialog.changeBtnLoadingStatus('confirm', true);
    const reqData = {
      od_link_id: selectedList,
      move_type: moveType,
    };
    actions.orderServer.delMove(reqData).then(res => {
      thisDialog.changeBtnLoadingStatus('confirm', false);
      thisDialog.handleHide();
      handleResponse({ operation: '删除' }, res, () => {
        setTimeout(() => {
          that.handleRefreshData && that.handleRefreshData();
        }, 1000);
      });
    });
  });
};
// 多货网点中转 中转重量体积修改弹窗
export const orderPointTransGoods = async (type = 'point_trans', data, rowIndex) => {
  const goodsInfo = {};
  const fieldMap = TRANS_FIELD_MAP[type];
  const multiFields = TRANS_FIELD_MAP.multiFields || [];
  const { title } = fieldMap;
  multiFields.forEach(key => {
    const field = fieldMap.noDetail ? fieldMap[key] : `${fieldMap[key]}_detail`;
    if (!typeIs(data[rowIndex][field], 'array')) {
      goodsInfo[key] = data[rowIndex][field].split('，').map(v => +v.replace(',', ''));
    } else {
      goodsInfo[key] = data[rowIndex][field];
    }
  });
  const num = data[rowIndex][fieldMap.num] || 0;
  const maxLen = Math.max(goodsInfo.weight.length, goodsInfo.volume.length);
  return await moreGoodsChange({ data: goodsInfo, goodsNum: maxLen, num, title });
};
// 多货网点中转 中转重量体积修改弹窗
// export const orderPointTransGoods = (type = 'point_trans', that, row, ...resParam) => {
//   let data = that.getTableData().data
//   let goodsInfo = {}, fieldMap = transFieldMap[type], multiFields = transFieldMap.multiFields || []
//   let resetCallback = resParam[resParam.length - 1]
//   // let moreGoodsFilter = ['point_trans_w_detail', 'point_trans_v_detail']
//   multiFields.forEach((key) => {
//     let field = `${fieldMap[key]}_detail`
//     if (!typeIs(data[row][field], 'array')) {
//       goodsInfo[key] = data[row][field].split('，').map(v => +v)
//     } else {
//       goodsInfo[key] = data[row][field]
//     }
//   })
//   // moreGoodsFilter.forEach(field => {
//   //   if (!typeIs(data[row][field], 'array')) {
//   //     goodsInfo[field] = data[row][field].split('，').map(v => +v)
//   //   } else {
//   //     goodsInfo[field] = data[row][field]
//   //   }
//   // })
//   // let w = goodsInfo.point_trans_w_detail// 中转重量
//   // let v = goodsInfo.point_trans_v_detail// 体积
//   // let num = data[row][fieldMap[num]].map(vv => +vv).reduce((a, b) => +a + b, 0) || 0 // 总件数
//   let num = data[row][fieldMap.num] || 0 // 总件数
//   let maxLen = Math.max(goodsInfo.weight.length, goodsInfo.volume.length)
//   let goods = JSON.parse(data[row].goods_str)
//   moreGoodsChange({
//     data: goodsInfo, goodsNum: maxLen, num
//   }).then(res => {
//     // let { weight, volume } = res
//     let curData = data
//     multiFields.forEach((key) => {
//       curData[row][`${fieldMap[key]}_detail`] = res[key]
//       // 更新合计值
//       curData[row][fieldMap[key]] = res[key].reduce((a, b) => +a + +b, 0)
//     })
//     // curData[row].point_trans_v = volume
//     // curData[row].point_trans_w = weight
//     // 更新货物信息
//     goods.forEach((vv, i) => {
//       multiFields.forEach((key) => goods[i][key] = res[key][i])
//       // goods[i].volume = volume[i]
//       // goods[i].weight = weight[i]
//     })
//     curData[row].goods = goods
//     that.resetData(curData)
//     resetCallback && typeof resetCallback === 'function' && resetCallback(curData)
//   }).catch(() => console.log('cancel'))
// }
// 订单 投保
export const orderInsurance = (that, ikey, rowIndex) => {
  let rowData = {};
  let odLinkIdList = [];
  if (typeIs(rowIndex, 'number')) {
    rowData = that.getData()[rowIndex] || {};
    odLinkIdList = [rowData.od_link_id];
  } else {
    ({ od_link_id: odLinkIdList } = getData(['od_link_id'], that));
  }
  // let selectedList = that.getSelectesKey('od_link_id').toString()
  insureOrder(odLinkIdList.toString()).then(result => result && that.handleRefreshData());
};

// 订单合运单 自动合单
export const mergeReservationAuto = that => {
  const list = that.getStateSelecteRows();
  if (!checkSelected(list, false, '要操作的订单！')) return false;
  const mergeSet = (window.company_setting.merge_set && window.company_setting.merge_set.value) || ['1'];
  const comparefn = (set, key, item, i) => {
    if (mergeSet.includes(set) && i > 0) {
      return !item[key] || !list[i - 1][key] || item[key] !== list[i - 1][key];
    }
    return false;
  };
  const validateArr = [];
  const merge_set_enum = ['收货人+收货人手机号', '下单时间（天）', '发货单位', '收货单位', '收货地址'];
  const notSame = list.some((item, i) => {
    const ceeNotSame = comparefn('1', 'cee_name', item, i) || comparefn('1', 'cee_mobile', item, i);
    const corComNotSame = comparefn('3', 'cor_com', item, i);
    const ceeComNotSame = comparefn('4', 'cee_com', item, i);
    const ceeAddrNotSame = comparefn('5', 'cee_addr_info', item, i);
    let dateNotSame = false;
    if (mergeSet.includes('2') && i > 0) {
      const date1 = item.reservation_date;
      const date2 = list[i - 1].reservation_date;
      dateNotSame = date1.split(' ')[0] !== date2.split(' ')[0];
    }
    ceeNotSame && validateArr.push(merge_set_enum[0]);
    dateNotSame && validateArr.push(merge_set_enum[1]);
    corComNotSame && validateArr.push(merge_set_enum[2]);
    ceeComNotSame && validateArr.push(merge_set_enum[3]);
    ceeAddrNotSame && validateArr.push(merge_set_enum[4]);
    return ceeNotSame || corComNotSame || ceeComNotSame || ceeAddrNotSame || dateNotSame;
  });
  if ([1, 2].includes(+window.company_info.type) || +window.company_info.to_transit_shed) {
    const reason = {
      customFailDesc: '总部和职能机构不能操作合单！',
      reason: (
        <p>
          合单属于开单操作，只有分拨中心/网点/货站才能操作。如总部/职能机构要替业务组织操作，可先切换组织到需要合单的组织，再操作合单。
        </p>
      ),
    };
    alert(WARN, <DialogDetail noticeTitle="原因说明：" succedLength={0} ext={reason} />);
    return false;
  }
  if (window.company_setting.reservation_order_num_same.checked) {
    alert(WARN, '您设置了“订单号与运单号保持一致”，多个订单无法合并成一个运单！如需合单，请先修改运单设置！');
    return false;
  }

  const merge = () => {
    if (window.company_setting.order_num.checked === 'manual') {
      alert(
        WARN,
        '您设置了“运单号生成规则为手动输入”，合并运单时无法生成运单号！如需合单，请先到运单设置里修改“运单号规则设置”。',
      );
      return false;
    }
    new PopUp(MergeReservationModal, { onClose: () => that.handleRefreshData(), data: list }).show();
  };
  if (notSame) {
    const detail = customDetail({
      caption: '操作提示：',
      detail: [
        '点击[继续]，系统会根据“自动合单匹配项”和“货物行数上限”自动合并成多个运单，如订单数据不全（例如订单里可能没有运费），需要在合单后操作改单补全运单信息。',
        '点击[取消]，可重新选择订单设置中合单匹配项的订单，在合单时会打开开单页，可以手动编辑合并后的运单信息，避免再操作一次改单。',
      ],
      hasDot: true,
    });

    confirm(
      WARN,
      `所选订单的${validateArr.join('、')}不一致，是否继续？`,
      { confirm: '继续' },
      '操作确认',
      detail,
    ).then(res => {
      if (!res) return false;
      merge();
    });
    return;
  }

  // 合单
  merge();
};

// 订单合运单 手动合单
export const mergeReservationManual = that => {
  const list = that.getStateSelecteRows();
  if (!checkSelected(list, false, '要操作的订单！')) return false;
  const info = {
    start_point_id: list[0].start_point_id,
    cor_name: list[0].cor_name,
    cor_mobile: list[0].cor_mobile,
    cee_name: list[0].cee_name,
    cee_mobile: list[0].cee_mobile,
  };
  const pass = list.every(item => Object.keys(info).every(key => info[key] === item[key]));

  if (!pass) {
    const contentNotice = (
      <div className="alert-content">
        <div className="alert-red">操作提示：</div>
        <p>已选订单不符合手动合单的要求，只有“出发网点”、“发货人”、“收货人”相同的订单才可以合并为一单！</p>
      </div>
    );
    alert(WARN, '已勾选的订单无法进行合单！', { confirm: '确定' }, contentNotice);
    return false;
  }

  const related = [];
  const notAccept = [];
  const obsoleted = [];
  const deleted = [];
  const rejected = [];
  const success = [];
  const successDetail = [];
  const maxGoodsNum = +_.get(window.company_setting, 'merge_goods_limit.value');
  list.forEach(item => {
    if (item.od_basic_id) return related.push(item.reservation_num);
    if (+item.accept === 0) return notAccept.push(item.reservation_num);
    if (+item.obsolete === 1) return obsoleted.push(item.reservation_num);
    if (+item.del_status) return deleted.push(item.reservation_num);
    if (+item.accept === 2) return rejected.push(item.reservation_num);
    success.push(item.reservation_num);
    successDetail.push(item);
  });

  const failedDetail = [
    ...related.map(item => ({ msg: '已关联运单：', number: item })),
    ...notAccept.map(item => ({ msg: '未受理，请先受理再操作合单：', number: item })),
    ...obsoleted.map(item => ({ msg: '已作废：', number: item })),
    ...deleted.map(item => ({ msg: '已删除：', number: item })),
    ...rejected.map(item => ({ msg: '已拒绝：', number: item })),
  ];

  const merge = () => {
    const goodsNumSum = successDetail.reduce((a, b) => a + (b.g_name || []).length, 0);
    if (maxGoodsNum && goodsNumSum > maxGoodsNum) {
      return alert(ERROR, '手动合单失败，订单货物行数大于“自动合单货物行数上限”设置的货物上限，无法合单。');
    }
    directToOrderCreator({ reservation_arr: JSON.stringify(success) });
  };
  opTips({
    orderType: '订单',
    opType: '合单',
    ext: { noSplice: true },
    showSucTips: false,
    resData: {
      errno: success.length ? 0 : -99999,
      res: {
        failed_detail: failedDetail,
        success_id: success,
      },
    },
    continueCallback(ids, cb) {
      cb();
      merge();
    },
    sucCallback: merge,
  });
};

// 订单拆运单  自动拆单
export const splitReservation = (that, key, index) => {
  const list = typeIs(index, 'number') ? [that.getData()[index]] : that.getStateSelecteRows();
  if (!list.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return false;
  }
  if ([1, 2].includes(+window.company_info.type) || +window.company_info.to_transit_shed) {
    const reason = {
      customFailDesc: '总部和职能机构不能操作订单拆单！',
      reason: (
        <p>
          订单拆单属于开单操作，只有分拨中心/网点/货站才能操作。如总部/职能机构要替业务组织操作，可先切换组织到需要合单的组织，再操作转运单。
        </p>
      ),
    };
    alert(WARN, <DialogDetail noticeTitle="原因说明：" succedLength={0} ext={reason} />);
    return false;
  }
  confirm(
    WARN,
    '系统将按订单的货物种类，自动拆分成多个运单。您确定要自动拆单吗？',
    { confirm: '确定' },
    '操作确认',
  ).then(res => {
    if (!res) return false;
    const rejected = [];
    const up = [];
    const success = [];
    const data = [];

    list.forEach(item => {
      if (+item.reservation_status !== 15 && +item.r_split_status !== 23) return rejected.push(item.reservation_num);
      if (+item.reservation_source === 5) return up.push(item.reservation_num);
      success.push(item.reservation_num);
      data.push(item);
    });

    const failedDetail = [
      ...rejected.map(item => ({ msg: '状态不符合拆单要求，只有“已受理”和“部分拆单”的订单可以拆单：', number: item })),
      ...up.map(item => ({ msg: '来自开放平台，上游系统不允许拆：', number: item })),
    ];
    const showSplitModal = () =>
      new PopUp(SplitReservationModal, { onClose: () => that.handleRefreshData(), data }).show();
    opTips({
      orderType: '订单',
      opType: '拆单',
      ext: { noSplice: true },
      showSucTips: false,
      resData: {
        errno: success.length ? 0 : -99999,
        res: {
          failed_detail: failedDetail,
          success_id: success,
        },
      },
      continueCallback(ids, cb) {
        cb();
        showSplitModal();
      },
      sucCallback: showSplitModal,
    });
  });
};

// 订单拆运单 手动拆单
export const splitReservationManual = (that, key, index) => {
  const list = typeIs(index, 'number') ? [that.getData()[index]] : that.getStateSelecteRows();
  if (!list.length) {
    showInfo(ERROR, '请选择要操作的数据');
    return;
  }
  if (list.length > 1) {
    showInfo(ERROR, '只能操作一行');
    return;
  }
  if ([1, 2].includes(+window.company_info.type) || +window.company_info.to_transit_shed) {
    const reason = {
      customFailDesc: '总部和职能机构不能操作订单拆单！',
      reason: (
        <p>
          订单拆单属于开单操作，只有分拨中心/网点/货站才能操作。如总部/职能机构要替业务组织操作，可先切换组织到需要合单的组织，再操作转运单。
        </p>
      ),
    };
    alert(WARN, <DialogDetail noticeTitle="原因说明：" succedLength={0} ext={reason} />);
    return false;
  }
  const data = list[0];
  const keys = ['num', 'weight', 'volume'];
  let msg = '';
  if (keys.every(k => !data[`g_${k}`] || data[`g_${k}`].every(v => !+v))) {
    msg = '订单的件数、重量、体积都为0，无法拆单！';
  } else if (+data.reservation_status !== 15 && +data.r_split_status !== 23) {
    msg = '订单状态不符合拆单要求，只有“已受理”和“部分拆单”的订单可以拆单';
  } else if (+data.reservation_source === 5) {
    msg = '订单来自开放平台，上游系统不允许拆单';
  } else if (window.company_setting.reservation_order_num_same.checked) {
    msg = '您设置了“订单号与运单号保持一致”，一个订单无法拆成多个运单！如需拆单，请先修改运单号规则设置。';
  } else if (window.company_setting.order_num.checked === 'manual') {
    msg = '您设置了“运单号手动输入”，拆单时无法生成运单号！如需拆单，请先修改运单号规则设置。';
  }
  if (msg) {
    return commonErrorTip({ title: '无法拆单，请检查后重新选择！', caption: '原因说明', detail: [msg] });
  }
  const onSaveSuccess = btnType => btnType === 'save_new' && setTimeout(that.handleRefreshData, 1000);
  new PopUp(SplitReservationManual, { onSaveSuccess, data }).show();
};
// 取消拆订单
export const cancelSplitReservation = async (that, ikey, rowIndex) => {
  let rowData = {};
  let odLinkIdList = [];
  if (typeIs(rowIndex, 'number')) {
    rowData = that.getData()[rowIndex] || {};
    odLinkIdList = [rowData.od_link_id];
  } else {
    try {
      ({ od_link_id: odLinkIdList } = await preArrivalCheck(that, '取消拆单', ['od_link_id']));
    } catch (error) {
      return;
    }
    // ({ od_link_id: odLinkIdList } = getData(['od_link_id'], that))
  }
  const type = 'do_deserted'; // do_deserted 运单作废 cancel_deserted 运单取消作废
  const reason = '';
  const url = 'Order/Order/orderDesertedOp';
  const pack = {
    type,
    reason,
    od_link_ids: odLinkIdList,
  };
  if (!checkSelected(odLinkIdList)) return;
  let popDialog = null;
  const popup = new PopUp(Dialog, {
    classname: 'fn-orderOp', // dialog class
    ref: r => (popDialog = r),
    loadBtnList: ['confirmDeleteOrder'],
    dialogInfo: {
      title: '取消拆单',
      contentHeader: null,
      button: [
        {
          key: 'confirmDeleteOrder',
          children: '确定',
          type: 'primary',
        },
        {
          key: 'cancel',
          children: '取消',
        },
      ],
      actionList: {
        confirmDeleteOrder: (key, data, validateInfo) => {
          if (!validateInfo.isPass) {
            showInfo(ERROR, validateInfo.error.labelProps.children + validateInfo.errorInfo);
            popDialog && popDialog.ajaxFinish();
            return false;
          }
          // popup.close()
          postDataOperate(
            url,
            Object.assign(pack, data),
            '取消拆单',
            {},
            () => {
              popDialog && popDialog.ajaxFinish();
              popup && popup.close();
              that.handleRefreshData && that.handleRefreshData();
            },
            'orderDesert',
            {
              failedCallback: () => {
                popDialog && popDialog.ajaxFinish();
                popup && popup.close();
              },
            },
          );
        },
        cancel: () => {
          popup && popup.close();
        },
      },
    },
    data: {
      reason: {
        type: 'TextArea',
        eventNames: ['onChange'],
        validate: ['require'],
        labelProps: {
          children: '取消原因',
          isRequired: true,
        },
        otherProps: {
          defaultValue: '',
          classname: 'od-reason',
          style: {
            minWidth: 390,
            maxWidth: 390,
            height: 64,
          },
          disabled: false,
          placeholder: '',
        },
      },
    },
    isShow: true,
  }).show();
};
// 取消关联运单

export const cancelRelateOrder = (that, btnKey) => {
  const selectedList = that.getStateSelecteRows();
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的订单！');
    return false;
  }
  const isWhole = btnKey === 'cancel_relate_task'; // 是否为整车订单取消关联任务
  let comIds;
  if (isWhole) {
    comIds = that.getSelectesKey('start_point_id');
    console.log(comIds, 5555);
  }
  const tipStr = isWhole ? '任务' : '运单';
  let checkBoxStatus = false;
  const checkbox = (
    <div>
      <p>
        <em>●</em>取消关联单号后，会把所有关联此任务的整车订单一同解除关联
      </p>
      <p>
        <em>●</em>同时删除关联的任务
      </p>
      <CheckBox label="删除" direction="right" onClick={e => (checkBoxStatus = e)} />
    </div>
  );
  confirmWithReasonSync({
    title: '操作确认',
    iconType: WARN, // icon
    tips: '您确定要取消关联任务吗？取消操作不可逆，请谨慎操作！', // 顶部提示语
    noticeTitle: '操作提醒',
    reason: checkbox,
    btnText: { confirm: '确认', cancel: '取消' },
    btns: { confirm: 1, cancel: 1 },
  }).then(result => {
    if (!result) return false;

    if (+window.company_info.to_transit_shed) {
      const reason1 = {
        customFailDesc: `总部和职能机构不能操作取消关联${tipStr}！`,
        reason: (
          <p>{`取消关联运单属于删除${tipStr}操作，只有分拨中心/网点/货站才能操作。如总部/职能机构要替业务组织操作，可先切换组织到开单网点，再操作取消关联${tipStr}。`}</p>
        ),
      };
      alert(WARN, <DialogDetail noticeTitle="原因说明：" succedLength={0} ext={reason1} />);
      return false;
    }
    const url = isWhole ? '/cmm-batch/batch/cancelRelateTask' : '/Reservation/Reservation/cancelRelateOrder';
    const req = isWhole ? { company_id: comIds, reservantion_id: selectedList } : { id: selectedList };
    const callback = () => setTimeout(() => that.handleRefreshData(), 1500);
    new PopUp(CancelRelateModal, { onClose: callback, data: selectedList, delete: checkBoxStatus }).show();
    // postDataOperate(url, req, `取消关联${tipStr}`, {}, callback, 'cancelRelateOrder', { noSplice: true }, '订单', 'id', isWhole)
  });
};
// 取消货物流水号关联运单
export const cancelGoodsRelateOrder = that => {
  const selectedList = that.getSelectesKey('id');
  if (!selectedList.length) {
    showInfo(ERROR, '请选择要操作的数据！');
    return false;
  }
  const url = 'Order/OrderSub/cancelSubRelate';
  const req = { ids: selectedList };
  const callback = () => setTimeout(() => that.handleRefreshData(), 1500);
  postDataOperate(
    url,
    req,
    '取消关联运单',
    {},
    callback,
    'cancelGoodsRelateOrder',
    { noSplice: true },
    '货物流水号',
    'ids',
  );
};
export const mergeReservations = that => {
  // console.log(that.getStateSelecteRows(), that.getData())
  const data = that.getStateSelecteRows();
  const isZC = data.filter(item => item.business_type !== '整车订单');
  console.log(data, 'datadata');
  if (data.length <= 1) {
    showInfo(ERROR, '至少选择两个及两个以上的订单进行合单');
    return;
  } else if (data.length >= 31) {
    showInfo(ERROR, '最多操作30个订单合单');
    return;
  } else if (isZC.length !== 0) {
    showInfo(ERROR, '只有整车订单才能操作合单');
    return;
  }
  const ids = that.getSelectesKey('id');
  const params = { ids, is_check: 1 };
  const postDatas = datas => {
    postJAVA('/cmm-batch/batch/mergeReservations', datas).then(res => {
      if (res.res.failed_detail && res.res.failed_detail.length !== 0) {
        opTips({
          resData: res,
          orderType: '订单',
          continueCallback: (sucId, cb) => {
            // 继续 btn 对应的回调
            cb && cb();
            // req: JSON.stringify(sucId)
            directToTaskCreator({ isReservation: true, req: JSON.stringify(sucId) });
          },
          sucCallback: () => {
            // 成功的回调
            setTimeout(that.handleRefreshData, 2000);
          },
        });
      } else {
        directToTaskCreator({ isReservation: true, req: JSON.stringify(ids) });
      }
    });
  };
  postDatas(params);
};
// 发车结算
export const startSettle = async that => {
  const fitlerQuery = that.getApiSearchFilter();
  const comIdArr = fitlerQuery && fitlerQuery.query ? fitlerQuery.query.com_id : [];
  if (comIdArr && comIdArr.length > 1) {
    showInfo(ERROR, '请在网点筛选中选择一个网点进行操作！');
    return false;
  }

  const selectedComId = that.getSelectesKey('com_id');
  const comIdSole = Array.from(new Set(selectedComId)); // 去重
  if (comIdSole.length > 1) {
    showInfo(ERROR, '一次只能操作同一个网点的数据！');
    return false;
  }
  const comID = comIdSole[0] || (comIdArr && comIdArr[0]) || window.company_id;

  const selectedList = that.getSelectesKey('od_link_id');

  that.disableMenuButton('start_settle');
  const url = '/Finance/Settle/getBLinkIdByOrder';
  const req = { type: 'batch_start', com_id: comID, link_ids: selectedList };
  const conf = { method: 'POST', body: { req } };
  const res = await fetchApi(url, conf);
  if (res.errno !== 0) {
    return false;
  }
  that.enableMenuButton('start_settle');
  const linkIds = res.res.link_ids || [];
  console.log('res=', fetchApi);

  const pickUrl = '/Finance/Settle/onBillBuilding';
  const pickReq = {
    buttonKey: 'settle',
    category: 'Batch',
    tab: 'settle_batch_start',
    type: 'settle_batch_start',
    com_id: comID,
    link_ids: linkIds,
    requirePayMode: false,
  };
  const pickConf = { method: 'POST', body: { req: pickReq } };
  const pickRes = await fetchApi(pickUrl, pickConf);
  if (pickRes.errno !== 0) return false;
  console.log('pickRes=', pickRes);

  // 跳转左右挑单
  const pickOrderUrl = {};
  pickOrderUrl.controller = 'Finance';
  pickOrderUrl.action = 'pickOrder';
  pickOrderUrl.query = {
    // 是否来自挑单夹
    fromPickClip: false,
  };
  pickOrderUrl.query.tabName = '结算发车批次';
  const pickOrderPack = {};
  pickOrderPack.pickOrderInfo = pickRes.res;
  pickOrderPack.sendReq = false;
  pickOrderPack.category = 'Batch';
  pickOrderPack.type = 'settle_batch_start';
  pickOrderPack.tab = 'settle_batch_start';
  pickOrderPack.com_id = comID;
  pickOrderPack.link_ids = linkIds;
  pickOrderPack.buttonKey = 'settle';
  actions.api.direct(pickOrderUrl, 'prevPagePack', pickOrderPack);
};
// 到车结算
export const arrSettle = async that => {
  const fitlerQuery = that.getApiSearchFilter();
  const comIdArr = fitlerQuery && fitlerQuery.query ? fitlerQuery.query.com_id : [];
  if (comIdArr && comIdArr.length > 1) {
    showInfo(ERROR, '请在网点筛选中选择一个网点进行操作！');
    return false;
  }

  const selectedComId = that.getSelectesKey('com_id');
  const comIdSole = Array.from(new Set(selectedComId)); // 去重
  if (comIdSole.length > 1) {
    showInfo(ERROR, '一次只能操作同一个网点的数据！');
    return false;
  }
  const comID = comIdSole[0] || (comIdArr && comIdArr[0]) || window.company_id;

  const selectedList = that.getSelectesKey('od_link_id');

  that.disableMenuButton('arr_settle');
  const url = '/Finance/Settle/getBLinkIdByOrder';
  const req = { type: 'batch_arr', com_id: comID, link_ids: selectedList };
  const conf = { method: 'POST', body: { req } };
  const res = await fetchApi(url, conf);
  if (res.errno !== 0) {
    return false;
  }
  that.enableMenuButton('arr_settle');
  const linkIds = res.res.link_ids || [];

  const pickUrl = '/Finance/Settle/onBillBuilding';
  const pickReq = {
    buttonKey: 'settle',
    category: 'Batch',
    tab: 'settle_batch_to',
    type: 'settle_batch_to',
    com_id: comID,
    link_ids: linkIds,
    requirePayMode: false,
  };
  const pickConf = { method: 'POST', body: { req: pickReq } };
  const pickRes = await fetchApi(pickUrl, pickConf);
  if (pickRes.errno !== 0) return false;
  console.log('pickRes=', pickRes);

  // 跳转左右挑单
  const pickOrderUrl = {};
  pickOrderUrl.controller = 'Finance';
  pickOrderUrl.action = 'pickOrder';
  pickOrderUrl.query = {
    // 是否来自挑单夹
    fromPickClip: false,
  };
  pickOrderUrl.query.tabName = '结算发车批次';
  const pickOrderPack = {};
  pickOrderPack.pickOrderInfo = pickRes.res;
  pickOrderPack.sendReq = false;
  pickOrderPack.category = 'Batch';
  pickOrderPack.type = 'settle_batch_to';
  pickOrderPack.tab = 'settle_batch_to';
  pickOrderPack.com_id = comID;
  pickOrderPack.link_ids = linkIds;
  pickOrderPack.buttonKey = 'settle';
  actions.api.direct(pickOrderUrl, 'prevPagePack', pickOrderPack);
};

// 中转到达
export const transArrival = (that, btnKey) => {
  const data = that.getSelectesKey('od_link_id');
  if (!data) return;
  if (!data.length) {
    createTip('请选择要操作的数据', ERROR).show();
    return;
  }
  if (data.length > 10) {
    createTip('为了保证数据的准确性，一次最多操作10条数据！', ERROR).show();
    return;
  }
  new PopUp(TransArrivalDialog, {
    odLinkIds: data,
    callback: that.handleRefreshData,
  }).show();
};

const scanReceipt = (that, btnKey) => {
  const data = that.getSelectesKey('od_link_id');
  scanReceiptCore(data, that, btnKey);
};

const offlinePay = (that, btnKey) => {
  const data = that.getSelectesKey(['od_link_id', 'pay_owed']);
  if (!data.length) {
    return showInfo(ERROR, '请选择您要操作的运单！');
  }
  let doComfirm;
  const post = (ids, check) => {
    that.disableMenuButton(btnKey);
    fetchApi('Order/Order/offlinePay', {
      method: 'POST',
      body: {
        req: {
          od_link_ids: ids,
          is_check: check,
          type: 1,
        },
      },
    }).then(res => {
      that.enableMenuButton(btnKey);
      opTips({
        resData: res,
        opType: '线下支付',
        tips: '线下支付',
        continueCallback(sucIds, cb) {
          doComfirm(sucIds, res.res.pay_owed);
          cb();
        },
        showSucTips: !check,
        sucCallback() {
          if (check) {
            doComfirm(ids, res.res.pay_owed);
          } else {
            setTimeout(that.handleRefreshData, 1000);
          }
        },
      });
    });
  };
  doComfirm = (ids, money) => {
    const content = (
      <p>
        是否已收到运费？欠付运费金额：<span style={{ color: 'red' }}>{money}元</span>
        <br />
        收到欠付运费后此运单将移入开单记录并可进行业务操作
      </p>
    );
    const handle = {
      confirm: () => {
        post(ids, 0);
      },
    };
    showInfo(WARN, content, true, handle, true);
  };

  post(
    data.map(i => i.od_link_id),
    1,
  );
};
const cancelOfflinePay = (that, btnKey) => {
  const data = that.getSelectesKey(['od_link_id', 'pay_owed']);
  if (!data.length) {
    return showInfo(ERROR, '请选择您要操作的运单！');
  }
  let doConfirm;
  const post = (ids, check) => {
    that.disableMenuButton(btnKey);
    fetchApi('Order/Order/offlinePay', {
      method: 'POST',
      body: {
        req: {
          od_link_ids: ids,
          is_check: check,
          type: 0,
        },
      },
    }).then(res => {
      that.enableMenuButton(btnKey);
      opTips({
        resData: res,
        opType: '取消线下支付',
        tips: '取消线下支付',
        continueCallback(sucIds, cb) {
          doConfirm(sucIds, res.res.pay_owed);
          cb();
        },
        showSucTips: !check,
        sucCallback() {
          if (check) {
            doConfirm(ids, res.res.pay_owed);
          } else {
            setTimeout(that.handleRefreshData, 1000);
          }
        },
      });
    });
  };
  doConfirm = (ids, money) => {
    const content = (
      <p>
        确定取消线下支付？欠付运费金额：<span style={{ color: 'red' }}>{money}元</span>
        <br />
        取消后此运单将被移入“未支付运单”列表，并无法进行业务操作
      </p>
    );
    const handle = {
      confirm: () => {
        post(ids, 0);
      },
    };
    showInfo(WARN, content, true, handle, true);
  };

  post(
    data.map(i => i.od_link_id),
    1,
  );
};

// 结束发运/取消结束发运
const end_despatch = async (that, btnKey) => {
  const ids = that.getSelectesKey('od_link_id');
  if (!ids || !ids.length) {
    return showInfo(ERROR, '请选择您要操作的订单！');
  }

  const is_cancel_end_despatch = btnKey === 'cancel_end_despatch';

  const check = await confirm(
    WARN,
    is_cancel_end_despatch
      ? '确定取消结束发运？'
      : '订单结束发运后将不能再进行配载，已配载的运单不可修改配载货量，确定结束发运吗？',
    { confirm: '确定' },
    '操作确认',
  );
  if (!check) return;

  const url = is_cancel_end_despatch ? '/Order/Order/cancelEndDespatch' : '/Order/Order/endDespatch';
  that.disableMenuButton(btnKey);

  // 批量
  if (ids.length > 1) {
    const selectedList = that.getSelectesKey(['id', 'od_link_id', 'order_num', 'com_id', 'otherProps']);
    const header = that.getHeader();
    new PopUp(BatchOp, {
      title: is_cancel_end_despatch ? '批量取消结束发运' : `批量结束发运`,
      tips: is_cancel_end_despatch ? '取消结束发运' : '结束发运',
      header: {
        // com_id: { title: '网点', display: 'show', dispay: 'show', flexGrow: 1 },
        order_num: { title: header?.order_num?.title ?? '订单编号', display: 'show', dispay: 'show' },
      },
      data: selectedList,
      requestUrl: url,
      prefixalTips: '',
      getPara: dataList => {
        return { od_link_id: dataList[0]?.od_link_id };
      },
      finishCallback: () => {
        that.handleRefreshData();
        that.enableMenuButton(btnKey);
      },
      escBind: false,
      isJAVA: false,
    }).show();
    return;
  }
  // 单条
  fetchApi(url, {
    method: 'POST',
    body: {
      req: {
        od_link_id: ids[0],
      },
    },
  })
    .then(res => {
      if (res?.errno !== 0) {
        showInfo(ERROR, res?.errmsg || '操作失败');
        return false;
      }
      showInfo(INFO, '操作成功', false);
      that.handleRefreshData();
    })
    .finally(e => {
      that.enableMenuButton(btnKey);
    });
};

// 虚拟发运
const virtualShip = async (that, buttonKey, index) => {
  const data = that?.vTable?.getStateSelecteRows() || [];
  datHelper(() => {
    dataAnalyticTrack([`【订单虚拟发运】`, `登记虚拟发运`, `登记虚拟发运-点击次数`]);
  });
  let formRef;
  if (data.length <= 0) {
    showInfo(ERROR, `请选择一条数据`);
    return;
  }
  if (data.length > 1) {
    showInfo(WARN, `请选择一条数据`);
    return;
  }

  that?.disableMenuButton(buttonKey);

  const res = await fetchApi('/Order/Order/virtualShipInfo', {
    method: 'POST',
    body: {
      req: {
        od_link_id: data[0].od_link_id,
      },
    },
  }).finally(() => {
    that?.enableMenuButton(buttonKey);
  });

  if (res?.errno !== 0) {
    datHelper(() => {
      dataAnalyticTrack([`【订单虚拟发运】`, `无法登记虚拟发运`, `无法登记虚拟发运-次数`]);
    });
    return showInfo(ERROR, res?.errmsg || '请求失败，请稍后重试或联系管理员！');
  }

  const buildModel = () => {
    const goodsLen = res?.res?.g_name?.length || 0;
    const goods = [];
    for (let i = 0; i < goodsLen; i++) {
      goods.push({
        g_id: res.res.g_id?.[i],
        g_name: res.res.g_name?.[i],
        g_code: res.res.g_code?.[i],
        g_bo_num: res.res.g_bo_num?.[i],
        g_weight: res.res.g_weight?.[i],
      });
    }

    const _header = that?.getHeader?.() ?? {};
    const header = {
      g_name: { ..._header?.g_name, title: _header?.g_name?.title ?? '货物名称' },
      g_code: { ..._header?.g_code, title: _header?.g_code?.title ?? '货物代码' },
      g_bo_num: { ..._header?.g_bo_num, title: _header?.g_bo_num?.title ?? '商流订单号' },
      g_weight: { ..._header?.g_weight, title: _header?.g_weight?.title ?? `虚拟发运量` },
    };

    const model = { od_link_id: data[0].od_link_id, goods, header };

    return model;
  };

  const model = buildModel();

  new PopUp(ShowInfo, {
    classname: 'virtual-ship__pop-up',
    title: '登记虚拟发运',
    showIcon: false,
    content: <VirtualShip ref={r => (formRef = r)} data={model} />,
    simpleMode: {
      confirm: async (t, ...p) => {
        t?.changeBtnLoadingStatus('confirm', true);
        const done = await formRef?.submit().finally(() => t?.changeBtnLoadingStatus('confirm', false));
        if (done) {
          t?.handleHide();
          setTimeout(() => that.handleRefreshData?.(), 1500);
        }
      },
      cancel: (t, ...p) => {
        t?.handleHide();
      },
    },
    closableCallback: t => {
      t?.handleHide();
    },
  }).show();
};

//  取消虚拟发运
const cancelVirtualShip = async (that, buttonKey, index) => {
  datHelper(() => {
    dataAnalyticTrack([`【订单虚拟发运】`, `撤销虚拟发运`, `撤销虚拟发运-点击次数`]);
  });
  const data = that?.vTable?.getStateSelecteRows() || [];
  let formRef;
  if (data.length <= 0) {
    showInfo(ERROR, `请选择一条数据`);
    return;
  }
  if (data.length > 1) {
    showInfo(WARN, `请选择一条数据`);
    return;
  }
  const checked = await confirm(WARN, '确定要撤销虚拟发运吗', {}, '操作确认');
  if (!checked) return;

  that?.disableMenuButton(buttonKey);

  const res = await fetchApi('/Order/Order/undoVirtualShip', {
    method: 'POST',
    body: {
      req: {
        od_link_id: data[0].od_link_id,
      },
    },
  }).finally(() => {
    that?.enableMenuButton(buttonKey);
  });

  if (res?.errno === 0) {
    showInfo(CHECK, '撤销虚拟发运成功');
    setTimeout(() => that.handleRefreshData?.(), 1500);
    return;
  }
  datHelper(() => {
    dataAnalyticTrack([`【订单虚拟发运】`, `撤销虚拟发运失败`, `撤销虚拟发运失败-次数`]);
  });
  showInfo(ERROR, res?.errmsg ?? '撤销虚拟发运失败，请稍后重试！');
};

// 初始化函数
export function init(emt) {
  // 提货签收
  emt.on('pickupSign', sign);
  // 送货签收
  emt.on('deliverySign', sign);
  // 中转签收
  emt.on('transSign', sign);
  // 签收
  emt.on('sign', sign);
  // 单票签收
  emt.on('orderSignSingle', signSingle);
  // 取消签收
  emt.on('cancelSign', cancelSign);
  // 加入挑单夹
  emt.on('pickOrder', pickOrder);
  // 加入挑单夹(行操作)
  emt.on('orderPickOrder', orderPickOrder);
  // 外部中转
  emt.on('trans', trans);
  // 员工中转
  emt.on('transInner', transInner);
  // 网点中转
  emt.on('transPoint', transPoint);
  // 内部中转处理
  emt.on('transInnerDeal', transInnerDeal);
  // 内部中转取消
  emt.on('transInnerCancel', transInnerCancel);
  // 内部中转取消接收
  emt.on('transInnerCancelAccept', transInnerCancelAccept);
  // 外部中转取消接收
  emt.on('transCancelAccept', transCancelAccept);
  // 外部中转处理
  emt.on('transDeal', transDeal);
  emt.on('orderTransDeal', outerTransDealSingle);
  // 中转修改
  emt.on('transModify', transModify);
  // 外部中转取消
  emt.on('transCancel', transCancel);
  // 中转行内处理
  emt.on('orderTransInnerDeal', orderTransHandle);
  // // 内部中转转出 中转批次详情
  // emt.on('batchBInnerTransBatch', batchBInnerTransBatch)
  // 网点中转转出 中转批次详情
  emt.on('batchBPointTransBatch', batchBInnerTransBatch);
  // 网点中转转出 中转批次详情
  emt.on('orderBPointTransBatchNum', orderBPointTransBatchNum);
  // 网点中转转入 中转批次详情
  emt.on('orderBPointTransBatchNumExt', orderBPointTransBatchNum);
  emt.on('orderBPointTransInBatchNum', orderBPointTransInBatchNum);
  // // 内部中转转入批次列表详情
  // emt.on('batchBTransBatch', batchBInnerTransBatch)
  // 运单行内按钮删除运单
  emt.on('orderOrderDelete', orderDelete);
  // 删除运单
  emt.on('orderDelete', orderDelete);
  // 运单列表行内作废运单
  emt.on('orderOrderDesert', orderDesert);
  // 作废运单
  emt.on('orderDesert', orderDesert);
  // 运单列表行内取消作废
  emt.on('orderOrderDesertCancel', orderDisDesert);
  // 取消作废
  emt.on('orderDesertCancel', orderDisDesert);
  // 创建订单
  emt.on('addReservation', createOrderManage);
  // 创建整车订单
  emt.on('addZcReservation', createOrderManage);
  // 整车订单转任务
  // emt.on('toTask', toTask)
  // 整车订单关联任务
  emt.on('relateTask', reservationRelateOrder);
  // 受理订单
  emt.on('accept', reservationAccept);
  // 报价
  emt.on('quoted', reservationQuoted);
  // 取消受理订单
  emt.on('cancelAccept', reservationCancelAccept);
  // 取消报价
  emt.on('cancelQuoted', reservationCancelQuoted);
  // 拒绝订单
  emt.on('refuse', reservationRefuse);
  // 订单转运单
  emt.on('toOrder', reservationTurnToOrder);
  // 关联运单
  emt.on('relateOrder', reservationRelateOrder);
  // 订单作废
  emt.on('obsolete', reservationObsolete);
  emt.on('reservationObsolete', reservationObsoleteOperate);
  // 订单取消作废
  emt.on('cancelObsolete', reservationCancelObsolete);
  emt.on('reservationCancelObsolete', reservationCancelObsoleteOperate);
  // 揽收失败
  emt.on('collectFail', collectFail);
  // 订单删除
  emt.on('deleteReservation', reservationDelete);
  // 订单修改
  emt.on('editReservation', editReservation);
  emt.on('batchEditReservation', batchEditReservation);

  emt.on('cancelDeliveryDp', cancelDeliveryDp);
  emt.on('cancelPickupDp', cancelPickupDp);

  emt.on('deliveryDispatch', deliveryDispatch);
  emt.on('pickupDispatch', pickupDispatch);

  // 转确认
  emt.on('transConfirm', transConfirm);
  // 取消转确认
  emt.on('cancelTransConfirm', cancelTransConfirm);
  // 添加异动
  emt.on('createMove', createMove);
  // 添加网点中转异动
  emt.on('createPointMove', createMove);
  // 修改异动
  emt.on('updateMove', updateMove);
  // 审核异动
  emt.on('checkMove', checkMove);
  // 取消审核
  emt.on('cancelCheckMove', cancelCheckMove);
  // 删除异动
  emt.on('deleteMove', deleteMove);
  emt.on('orderState', OrderStateSlide);
  emt.on('orderRemovePickOrder', clearItem);
  emt.on('clearPickClip', clearAll);
  // 网点中转多货弹框
  // emt.on('orderPointTransVDetail', (...args) => orderPointTransGoods('point_trans', ...args))
  // emt.on('orderPointTransWDetail', (...args) => orderPointTransGoods('point_trans', ...args))
  // 外部中转多货弹框
  // emt.on('orderTransVDetail', (...args) => orderPointTransGoods('outer_trans', ...args))
  // emt.on('orderTransWDetail', (...args) => orderPointTransGoods('outer_trans', ...args))
  emt.on('orderInsurance', orderInsurance);
  emt.on('reservationReservationNum', viewReservation);
  emt.on('batchReservationNum', viewReservation);
  emt.on('settleReservation|reservationNum', viewReservation);
  emt.on('assignReservation', assignReservation);
  // 自动合单
  emt.on('mergeAuto', mergeReservationAuto);
  // 手动合单
  emt.on('mergeManual', mergeReservationManual);
  emt.on('mergerReservations', mergeReservations);
  // 自动拆单
  emt.on('splitAuto', splitReservation);
  // 手动拆单
  emt.on('splitManual', splitReservationManual);
  emt.on('cancelSplit', cancelSplitReservation);
  // 取消关联运单
  emt.on('cancelRelateOrder', cancelRelateOrder);
  emt.on('cancelRlt', cancelGoodsRelateOrder);
  emt.on('cancelRelateTask', cancelRelateOrder);
  // 发车结算
  emt.on('startSettle', startSettle);
  // 到车结算
  emt.on('arrSettle', arrSettle);
  // 中转到达
  emt.on('transArrival', transArrival);
  emt.on('scanReceipt', scanReceipt);

  // 线下支付
  emt.on('offlinePay', offlinePay);
  // 取消线下支付
  emt.on('cancelOfflinePay', cancelOfflinePay);
  // 线路货源明细表 查看线路
  emt.on('orderSegmentName', reportSegmentName);
  // 订单附件
  emt.on('orderCoImg', (that, index, buttonKey, data, img) => {
    viewImage(img, data.co_img || []);
  });
  // 结束发运
  emt.on('endDespatch', end_despatch);
  // 取消结束发运
  emt.on('cancelEndDespatch', end_despatch);

  // 虚拟发运
  emt.on('orderVirtualShip', virtualShip);
  // 取消虚拟发运
  emt.on('orderUndoVirtualShip', cancelVirtualShip);
}
